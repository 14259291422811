import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BedIcon from "../../../assets/bedIcon.svg";
import BathIcon from "../../../assets/bathIcon.svg";
import SqftIcon from "../../../assets/sqftIcon.svg";
import LikeIcon from "../../../assets/likedIcon.svg";
import { reactions } from "../../../services/reactions";
import UnlikeIcon from "../../../assets/unlikedIcon.svg";
import { formatPrice } from "../../../utils/formatPrice";
import { useUserProfileStore } from "../../../stores/profileStore";
import { useAuth } from "../../../context/authProvider";

interface PropertyCardProps {
	imageUrl: string;
	propertyType: string;
	status: string;
	price: number;
	downPayment?: number;
	date?: Date | null;
	address: string;
	beds: string;
	baths: string;
	sqft: string;
	similarListings: boolean;
	listingId: string;
	percentOverAsking?: string;
}

const PropertyCard = ({
	imageUrl,
	propertyType,
	status,
	price,
	downPayment,
	date,
	address,
	beds,
	baths,
	sqft,
	similarListings,
	listingId,
	percentOverAsking,
}: PropertyCardProps) => {
	const [likedProperties, setLikedProperties] = useState(false);
	const { userProfile } = useUserProfileStore();
	const navigate = useNavigate();
	const { isWebview } = useAuth();

	useEffect(() => {
		const isLiked = userProfile?.likedHomes?.some(
			(home) => home.listingId === listingId,
		);
		setLikedProperties(!!isLiked);
	}, [userProfile, listingId]);

	const handleAddToSavedProperties = async () => {
		try {
			await reactions(listingId, "SWIPE_RIGHT_TO_LIKE_LISTING");

			if (likedProperties) {
				await reactions(listingId, "REMOVE_SAVED_LISTING");
				setLikedProperties(false);
			} else if (!likedProperties) {
				await reactions(listingId, "SAVE_LISTING");
				setLikedProperties(true);
			}
		} catch (error) {
			console.error("Failed to add to cart", error);
		}
	};
	return (
		<div
			className="flex overflow-hidden flex-col rounded-[15px] bg-white bg-opacity-0 max-w-[346px] shadow-xl"
			onClick={() => navigate(`/listing/${listingId}`)}
			onKeyDown={() => navigate(`/listing/${listingId}`)}
		>
			<div className="flex flex-col w-full font-semibold text-white rounded-t-[15px]">
				<div className="flex overflow-hidden relative flex-col py-3 pr-2 w-full aspect-[1.632]">
					<img
						loading="lazy"
						src={imageUrl}
						alt="Property"
						className="object-cover absolute inset-0 size-full"
					/>
					<div
						className="absolute h-full top-4 w-full z-10 pr-4 flex items-start justify-between"
						onClick={() => navigate(`/listing/${listingId}`)}
						onKeyDown={() => navigate(`/listing/${listingId}`)}
					>
						<div className="px-4 py-2.5 text-xs font-medium leading-none bg-z-dark-moss-green rounded-r-full w-fit">
							{propertyType}
						</div>
					</div>
					{!isWebview && (
					<button
						type="button"
						onClick={handleAddToSavedProperties}
						className="absolute right-4 z-20"
					>
						<img
							src={likedProperties ? LikeIcon : UnlikeIcon}
							alt=""
							className="w-9"
						/>
					</button>)}
					<div className="absolute w-full clear-start flex items-center justify-between bottom-3 px-4 h-[28px]">
						<div
							className={`${
								similarListings ? "bg-z-dark-moss-green" : "bg-[#FF5757] "
							} rounded-[6px] px-2 h-full font-semibold text-sm text-center flex items-center`}
						>
							{status}
						</div>
						{percentOverAsking && (
							<div
								className={`px-2 rounded-[6px] ${
									Number.parseFloat(percentOverAsking) >= 0
										? "text-[#0A9D57] bg-[#D5EEE3]"
										: "text-white bg-[#FF5757]"
								} font-semibold text-xs h-full flex items-center justify-center`}
							>
								{`${Number.parseFloat(percentOverAsking).toFixed(2)}% ${
									Number.parseFloat(percentOverAsking) >= 0 ? "Above" : "Below"
								} Asking`}
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className="px-3 py-3 space-y-2"
				onClick={() => navigate(`/listing/${listingId}`)}
				onKeyDown={() => navigate(`/listing/${listingId}`)}
			>
				<div className="flex flex-col items-start w-full bg-white rounded-none">
					<div className="flex items-center justify-between w-full">
						<div
							className={`font-semibold text-xl ${
								similarListings ? "text-black" : "text-[#FF5757]"
							}`}
						>
							${formatPrice(price)}
						</div>
						{date && (
							<div className="text-[#676473] text-xs">
								{moment.utc(date).fromNow()}
							</div>
						)}
					</div>
					{downPayment && (
						<div className="flex items-center justify-between w-full font-semibold text-sm">
							<div
								className={`${similarListings ? "text-z-dark-moss-green" : ""}`}
							>
								Down Payment Provided
							</div>
							<div>${formatPrice(downPayment)}</div>
						</div>
					)}
					<div className="mt-1.5 text-sm font-light text-black">{address}</div>
				</div>
				<div className="flex items-center gap-1 justify-between w-full text-xs text-[#7D7D7D]">
					<div className="flex items-center gap-1.5">
						<img src={BedIcon} alt="Beds" className="w-[14px]" />
						<div>{beds} Beds</div>
					</div>
					<div className="flex items-center gap-1.5">
						<img src={BathIcon} alt="Baths" className="w-[14px]" />
						<div>{baths} Baths</div>
					</div>
					<div className="flex items-center gap-1.5">
						<img src={SqftIcon} alt="Sqft" className="w-[14px]" />
						<div>{formatPrice(sqft)}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { PropertyCard };
