import { nanoid } from "nanoid";
import { verifyAuth } from "../../services/auth";
import { LoadingCircle } from "../common/loading";
import { useAuth } from "../../context/authProvider";
import { useAuthStore } from "../../stores/useAuthStore";
import { PhoneValidator } from "../../utils/phoneValidator";
import { useLocation, useNavigate } from "react-router-dom";
import { useStep } from "../../stores/buyerHomeFeaturesStore";
import type { MainProfile } from "../../interfaces/mainProfile";
import { useUserProfileStore } from "../../stores/profileStore";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { useVerificationStore } from "../../stores/verificationStore";
import { fetchAccountData, updateAccountData } from "../../services/account";
import {
	useAdvertisementStep,
	useAdvertisementUserStore,
} from "../../stores/advertisementFlowStore";
import {
	type ChangeEvent,
	type KeyboardEvent,
	useRef,
	useState,
	useEffect,
	useCallback,
} from "react";

const LoginVerifyView = () => {
	const codeLength = 6;
	const { authEmail } = useAuthStore();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const { setAuthenticated } = useAuth();
	const { setUserProfile } = useUserProfileStore();
	const { setCurrentStep } = useStep();
	const location = useLocation();
	const origin = location.state?.origin;
	const { firstName, lastName, phoneNumber } = useAdvertisementUserStore();

	const { setAdvertisementStep } = useAdvertisementStep();

	const accountPayload: Partial<MainProfile> = {};

	if (firstName) {
		accountPayload.firstName = capitalizeFirstLetter(firstName);
	}
	if (lastName) {
		accountPayload.lastName = capitalizeFirstLetter(lastName);
	}
	if (phoneNumber) {
		let formattedPhoneNumber: string | undefined;
		try {
			if (PhoneValidator.isValid(phoneNumber, "CA")) {
				formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "CA");
			} else if (PhoneValidator.isValid(phoneNumber, "US")) {
				formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "US");
			} else {
				throw new Error("Invalid phone number format");
			}
			accountPayload.phoneNumber = formattedPhoneNumber;
		} catch (error) {
			console.error("Invalid phone number format", error);
			accountPayload.phoneNumber = "";
		}
	}

	const { setVerificationFailed } = useVerificationStore();
	const [showError, setShowError] = useState<boolean>(false);
	const [localCode, setLocalCode] = useState<string[]>(
		Array(codeLength).fill(""),
	);
	const generateInputIds = () =>
		Array.from({ length: codeLength }, () => nanoid());
	const [inputIds] = useState<string[]>(generateInputIds);
	const onboardingButtonRef = useRef<HTMLButtonElement>(null);

	const handleCodeInput = (
		event: ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const value = event.target.value;
		if (/^\d?$/.test(value)) {
			const newCode = [...localCode];
			newCode[index] = value;
			setLocalCode(newCode);

			if (value && index < codeLength - 1) {
				const nextInput = document.getElementById(
					inputIds[index + 1],
				) as HTMLInputElement;
				nextInput?.focus();
			}
		}
	};

	const handleKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (event.key === "Backspace") {
			const newCode = [...localCode];
			if (index > 0 && !newCode[index]) {
				newCode[index - 1] = "";
				setLocalCode(newCode);
				const prevInput = document.getElementById(
					inputIds[index - 1],
				) as HTMLInputElement;
				prevInput?.focus();
			} else {
				newCode[index] = "";
				setLocalCode(newCode);
			}
		} else if (event.key === "ArrowLeft" && index > 0) {
			const prevInput = document.getElementById(
				inputIds[index - 1],
			) as HTMLInputElement;
			prevInput?.focus();
		} else if (event.key === "ArrowRight" && index < codeLength - 1) {
			const nextInput = document.getElementById(
				inputIds[index + 1],
			) as HTMLInputElement;
			nextInput?.focus();
		}
	};

	const inputs = inputIds.map((id, index) => (
		<input
			key={id}
			type="tel"
			id={id}
			maxLength={1}
			className="border-[#979797]  border-2 rounded-lg py-4 px-2 font-medium w-12 text-center"
			value={localCode[index]}
			onChange={(e) => handleCodeInput(e, index)}
			onKeyDown={(e) => handleKeyDown(e, index)}
		/>
	));

	const validateCodeInput = async () => {
		const inputCode = localCode.join("");

		try {
			setLoading(true);
			const response = await verifyAuth(authEmail, inputCode);

			if (response.status !== 200) {
				setShowError(true);
				setVerificationFailed(true);

				if (origin === "verifyGuest") {
					navigate("/login/how-reach-you-view");
				} else {
					navigate("/login/email-view");
				}
				throw new Error("Verification failed");
			}

			if (origin === "verifyGuest") {
				if (Object.keys(accountPayload).length > 0) {
					const accountResponse = await updateAccountData(accountPayload);
					setUserProfile(accountResponse);
				}
				setAuthenticated(true);
				setAdvertisementStep(2);
				navigate("/income-view");
				return true;
			}

			const accountData = await fetchAccountData();
			setUserProfile(accountData);

			const { firstName, lastName, phoneNumber } = accountData;
			const missingEssentialDetails = !(firstName && lastName) || !phoneNumber;

			if (missingEssentialDetails) {
				setAuthenticated(true);
				setCurrentStep(0);
				navigate("/buyer-input-flow/details-input");
				return true;
			}

			const incomeAmount = accountData.finances?.income?.[0]?.amount || 0;
			const savingsAmount = accountData.finances?.savings?.[0]?.amount || 0;
			const monthlyDebtAmount =
				accountData.finances?.monthlyDebt?.[0]?.amount || 0;

			if (incomeAmount === 0) {
				setAuthenticated(true);
				setCurrentStep(1);
				navigate("/buyer-input-flow/annual-income");
				return true;
			}

			if (savingsAmount === 0) {
				setAuthenticated(true);
				setCurrentStep(2);
				navigate("/buyer-input-flow/saving");
				return true;
			}

			if (monthlyDebtAmount === 0) {
				setAuthenticated(true);
				setCurrentStep(3);
				navigate("/buyer-input-flow/monthly-debt");
				return true;
			}

			setAuthenticated(true);
			navigate("/");
			return true;
		} catch (error) {
			console.error("Verification failed:", error);
			setShowError(true);
			setVerificationFailed(true);
			navigate("/login/email-view");
			return true;
		} finally {
			setLoading(false);
		}
	};

	const handleKeyDownOnPage = useCallback((e: WindowEventMap["keydown"]) => {
		if (e.key === "Enter") {
			if (onboardingButtonRef.current) {
				onboardingButtonRef.current.click();
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDownOnPage);
		return () => {
			window.removeEventListener("keydown", handleKeyDownOnPage);
		};
	}, [handleKeyDownOnPage]);

	return (
		<div className="flex flex-col items-center justify-center h-full space-y-4">
			<h2 className="font-bold text-2xl text-center">Verify Account</h2>
			<div className="justify-center text-center text-[15px]">
				Enter the one-time code we sent to
				<br />
				<span className="text-black font-bold">{authEmail}</span>
			</div>
			{loading && (
				<div className="flex justify-center items-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && (
				<>
					<div className="flex space-x-2 justify-center">{inputs}</div>

					{showError && (
						<div className="text-red-500">
							Incorrect code. Please try again.
						</div>
					)}
					<div className="space-y-8 w-full flex flex-col items-center">
						<button
							type="button"
							className="underline h-10 w-full max-w-[200px] text-sm text-black"
							onClick={() => navigate("/login/email-view")}
						>
							Didn't get a code?
						</button>

						<button
							ref={onboardingButtonRef}
							type="button"
							className="bg-z-dark-moss-green text-white disabled:bg-[#E0E0E0] disabled:text-[#939393] w-full py-4 rounded-full font-bold shadow-md"
							onClick={validateCodeInput}
						>
							Continue
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export { LoginVerifyView };
