import { useNavigate } from "react-router-dom";
import CheckIcon from "../../assets/completedCheckIcon.svg";

const BookingSuccessView = () => {
	const navigate = useNavigate();
	return (
		<div className="text-black space-y-4 px-4 pt-2 pb-6">
			<div className="flex flex-col items-center text-center space-y-4">
				<img src={CheckIcon} alt="" />
				<div className="text-[22px] font-semibold">Congratulations!</div>
				<div>
					Your showing request has been received. You can check the status of
					your showing on the showings page.
				</div>
			</div>
			<div className="w-full space-y-2">
				<button
					type="button"
					className="bg-z-dark-moss-green text-white w-full rounded-[10px] py-3.5 text-[20px] font-semibold"
					onClick={() => {
						window
							._sendEvent("ZownAppBuyerBookShowingCompleted", {})
							.catch((error: unknown) => {
								console.error(error);
							})
							.finally(() => {
								navigate("/profile/showing");
							});
					}}
				>
					View booked showings
				</button>
				<button
					type="button"
					className="bg-white text-black w-full rounded-[10px] py-2 text-[20px] font-semibold"
					onClick={() => {
						window
							._sendEvent("ZownAppBuyerBookShowingCompleted", {})
							.catch((error: unknown) => {
								console.error(error);
							})
							.finally(() => {
								navigate("/");
							});
					}}
				>
					Go to Home
				</button>
			</div>
		</div>
	);
};

export { BookingSuccessView };
