import type { KeyboardEvent, RefObject } from "react";

export const handleKeyPress = (
	e: KeyboardEvent<HTMLDivElement>,
	ref: RefObject<HTMLInputElement>,
): void => {
	if (e.key === "Enter" || e.key === " ") {
		e.preventDefault();
		ref.current?.focus();
	}
};
