/**
 * Capitalizes the first letter of each word in a string.
 *
 * This function takes a string as input and converts the first letter of each word to uppercase,
 * leaving the rest of the word unchanged.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} - The resulting string with the first letter of each word capitalized.
 *
 * @example
 * // Returns 'Hello World'
 * capitalizeFirstLetter('hello world');
 */
export const capitalizeFirstLetter = (str: string): string => {
	return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
