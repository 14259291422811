import { universalFetch } from "./common";
import type { MainProfile } from "../interfaces/mainProfile";
import type { UpdateBuyerFinances } from "../interfaces/finance";

export const fetchAccountData = async (): Promise<MainProfile> => {
  try {
    const response = await universalFetch("/", "GET");
    console.log("fetchAccountData response:", response);
    return response as MainProfile;
  } catch (error) {
    console.error("Failed to fetchAccountData", error);
    throw error;
  }
};

export const updateAccountData = async (payload: Partial<MainProfile>) => {
  try {
    const response = await universalFetch("/", "PUT", payload);

    return response as MainProfile;
  } catch (error) {
    console.error("Failed to updateAccountData", error);
    throw error;
  }
};

export const updateFinancesData = async (
  payload: Partial<UpdateBuyerFinances>
) => {
  try {
    const response = await universalFetch("/finances", "PUT", payload);

    return response as MainProfile;
  } catch (error) {
    console.error("Failed to fetchUserProfile", error);
    throw error;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await universalFetch("/", "DELETE");
    console.log("deleteAccount response", response);
    return response;
  } catch (error) {
    console.error("Failed deleteAccount", error);
    throw error;
  }
};

export const logOut = async () => {
  try {
    const response = await universalFetch("/logout", "POST", {});
    console.log("logOut response", response);
    return response;
  } catch (error) {
    console.error("Failed to logOut:", error);
    throw error;
  }
};

export const getPreApproved = async (email?: string) => {
  try {
    const response = await universalFetch("/files/email-upload-link", "POST", {
      emailAddress: email,
    });
    console.log("getPreApproved response", response);
  } catch (error) {
    console.error("Failed to getPreApproved:", error);
    throw error;
  }
};

export const emailUploadPreApproval = async () => {
  try {
    const response = await universalFetch(
      "/preapprovals/email-start-link",
      "POST",
      {}
    );
    console.log("getPreApproved response", response);
  } catch (error) {
    console.error("Failed to getPreApproved:", error);
    throw error;
  }
};

export const uploadPreApprovalDoc = async (file: string[]) => {
  try {
    const response = await universalFetch("/files", "POST", { files: file });
    console.log("logOut response", response);
    return response;
  } catch (error) {
    console.error("Failed to logOut:", error);
    throw error;
  }
};

export const flinksData = async (
  loginId: string,
  requestId: string,
  institution: string
) => {
  try {
    const response = await universalFetch("/profile/flinks", "POST", {
      loginId,
      requestId,
      institution,
    });
    console.log("flinksData response", response);
    return response;
  } catch (error) {
    console.error("Failed flinksData", error);
    throw error;
  }
};

export const getContractUrl = async (listingIds: string[]) => {
  try {
    const response = await universalFetch("/contracts/sign", "POST", {
      listingIds,
    });
    console.log("getContractUrl response", response);
    return response;
  } catch (error) {
    console.error("getContractUrl flinksData", error);
    throw error;
  }
};
