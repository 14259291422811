import { create } from "zustand";

interface SortFilter {
  page: string;
  limit: string;
  sortBy: string;
  sortOrder: string;
}

interface MapFiltersState {
  beds: string[];
  setBeds: (beds: string[]) => void;

  mapSelectedCities: string[] | null;
  setMapSelectedCities: (city: string | null) => void;
  removeMapSelectedCity: (city: string) => void;

  baths: string[];
  setBaths: (baths: string[]) => void;

  sortFilter: SortFilter;
  setSortFilter: (update: Partial<SortFilter>) => void;

  parking: string[];
  setParking: (parking: string[]) => void;

  reloadMapCities: boolean;
  setReloadMapCities: (reload: boolean) => void;

  dens: boolean | null;
  setDens: (dens: boolean | null) => void;

  propertyType: string[];
  setPropertyType: (updateFn: (prevTypes: string[]) => string[]) => void;

  minPrice: number;
  setMinPrice: (minPrice: number) => void;

  maxPrice: number;
  setMaxPrice: (maxPrice: number) => void;

  daysOnMarket: string | null;
  setDaysOnMarket: (daysOnMarket: string | null) => void;

  ageOfProperty: string | null;
  setAgeOfProperty: (ageOfProperty: string | null) => void;

  style: string | null;
  setStyle: (style: string | null) => void;

  basement: string | null;
  setBasement: (basement: string | null) => void;

  sqft: string | null;
  setSqft: (sqft: string | null) => void;

  garageType: string | null;
  setGarageType: (garageType: string | null) => void;

  numberOfKitchens: string | null;
  setNumberOfKitchens: (numberOfKitchens: string | null) => void;

  clearAllFilters: () => void;
  getAllFilters: () => MapFiltersState;
}

export const useMapFiltersStore = create<MapFiltersState>((set, get) => ({
  beds: [],
  setBeds: (beds) => set({ beds }),

  baths: [],
  setBaths: (baths) => set({ baths }),

  parking: [],
  setParking: (parking) => set({ parking }),

  dens: null,
  setDens: (dens) => set({ dens }),

  propertyType: [],
  setPropertyType: (updateFn) =>
    set((state) => ({ propertyType: updateFn(state.propertyType ?? []) })),

  minPrice: 0,
  setMinPrice: (minPrice) => set({ minPrice }),

  maxPrice: 2000000,
  setMaxPrice: (maxPrice) => set({ maxPrice }),

  daysOnMarket: null,
  setDaysOnMarket: (daysOnMarket) => set({ daysOnMarket }),

  reloadMapCities: false,
  setReloadMapCities: (reload) => set({ reloadMapCities: reload }),

  ageOfProperty: null,
  setAgeOfProperty: (ageOfProperty) => set({ ageOfProperty }),

  mapSelectedCities: [] as string[],
  setMapSelectedCities: (city: string | null) =>
    set(() => ({
      mapSelectedCities: city ? [city] : null,
    })),

  removeMapSelectedCity: () =>
    set(() => ({
      mapSelectedCities: [],
    })),

  style: null,
  setStyle: (style) => set({ style }),

  basement: null,
  setBasement: (basement) => set({ basement }),

  sqft: null,
  setSqft: (sqft) => set({ sqft }),

  garageType: null,
  setGarageType: (garageType) => set({ garageType }),

  numberOfKitchens: null,
  setNumberOfKitchens: (numberOfKitchens) => set({ numberOfKitchens }),

  sortFilter: {
    page: "1",
    limit: "100",
    sortBy: "date",
    sortOrder: "desc",
  },
  setSortFilter: (update) =>
    set((state) => ({
      sortFilter: { ...state.sortFilter, ...update },
    })),

  clearAllFilters: () =>
    set({
      beds: [],
      baths: [],
      parking: [],
      dens: null,
      propertyType: [],
      minPrice: 0,
      maxPrice: 2000000,
      daysOnMarket: null,
      ageOfProperty: null,
      style: null,
      basement: null,
      sqft: null,
      garageType: null,
      numberOfKitchens: null,
      mapSelectedCities: null,
      sortFilter: {
        page: "1",
        limit: "100",
        sortBy: "date",
        sortOrder: "desc",
      },
    }),

  getAllFilters: () => get(),
}));
