interface LoadingCircleProps {
	height?: number;
	width?: number;
}

const LoadingCircle = ({ height = 80, width = 80 }: LoadingCircleProps) => (
	<div
		className="lds-roller"
		style={{ width: `${width}px`, height: `${height}px` }}
	>
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
	</div>
);

export { LoadingCircle };
