import millify from "millify";
import { Marker } from "react-map-gl";
import type { PointFeature } from "supercluster";
import HouseIcon from "../../../assets/icons/houseIcon.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import type { SearchListingInterface } from "../../../services/exploreListings";

interface ClusterProperties {
	cluster: boolean;
	point_count: number;
	cluster_id: number;
	propertyId?: string;
}

interface PointProperties extends SearchListingInterface {
	cluster: false;
	propertyId: string | number;
}

type MarkerPoint =
	| PointFeature<PointProperties>
	| PointFeature<ClusterProperties>;

interface MarkerProps {
	clusters: MarkerPoint[];
	onMarkerClick: (
		clusterId: number | null,
		longitude: number,
		latitude: number,
	) => void;
}

const Markers = ({ clusters, onMarkerClick }: MarkerProps) => {
	const renderClusterMarker = (pointCount: number) => (
		<div className="cluster-marker bg-z-dark-moss-green border-white border-4 shadow-lg text-white rounded-full w-[50px] h-[50px] text-xl flex items-center justify-center">
			{pointCount}
		</div>
	);

	const renderCustomMarker = (property: SearchListingInterface) => (
		<div className="bg-z-dark-moss-green font-medium text-[17px] border-white border-4 rounded-full px-2 py-1 shadow-lg text-white flex gap-2 items-center justify-center">
			<img src={HouseIcon} alt="House Icon" className="w-[16.49px]" />
			<span>
				{millify(convertToFormattedAmount(property.listPrice)) || "Property"}
			</span>
		</div>
	);

	return (
		<>
			{clusters.map((cluster) => {
				const [longitude, latitude] = cluster.geometry.coordinates;
				const {
					cluster: isCluster,
					point_count: pointCount,
					cluster_id: clusterId,
					...properties
				} = cluster.properties;

				if (isCluster) {
					return (
						<Marker
							key={`cluster-${clusterId}`}
							longitude={longitude}
							latitude={latitude}
						>
							<button
								type="button"
								onClick={() => onMarkerClick(clusterId, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderClusterMarker(pointCount)}
							</button>
						</Marker>
					);
				}

				if ((properties as SearchListingInterface).listingId) {
					return (
						<Marker
							key={`property-${(properties as SearchListingInterface).listingId}`}
							longitude={longitude}
							latitude={latitude}
						>
							<button
								type="button"
								onClick={() => onMarkerClick(null, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderCustomMarker(properties as SearchListingInterface)}
							</button>
						</Marker>
					);
				}

				return null;
			})}
		</>
	);
};

export { Markers };
