import { create } from "zustand";
import type { SearchListingInterface } from "../services/exploreListings";
// import type { SearchListingInterface } from "../interfaces/searchListing";

interface ListingState {
	listings: SearchListingInterface[];
	setListings: (listings: SearchListingInterface[]) => void;
	getListings: () => SearchListingInterface[];
}

interface SearchCitiesState {
	cities: string[];
	setCities: (cities: string[]) => void;
	getCities: () => string[];
}

interface SearchFilteredPropertiesState {
	filteredProperties: SearchListingInterface[];
	setFilteredProperties: (cities: SearchListingInterface[]) => void;
	getFilteredProperties: () => SearchListingInterface[];
}

export const useListingStore = create<ListingState>((set, get) => ({
	listings: [],
	setListings: (listings) => set({ listings }),
	getListings: () => get().listings,
}));

export const useSearchedCitiesStore = create<SearchCitiesState>((set, get) => ({
	cities: [],
	setCities: (cities) => set({ cities }),
	getCities: () => get().cities,
}));

export const useFilteredPropertiesStore = create<SearchFilteredPropertiesState>(
	(set, get) => ({
		filteredProperties: [],
		setFilteredProperties: (filteredProperties) => set({ filteredProperties }),
		getFilteredProperties: () => get().filteredProperties,
	}),
);
