import { useEffect } from "react";
import { useAuth } from "../context/authProvider.tsx";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthLayout = ({ type }: { type: "public" | "private" }) => {
	const { authenticated, isWebview } = useAuth();
	const location = useLocation();

	useEffect(() => {
		console.log("Current route:", location.pathname);
	}, [location]);


	if (type === "public" && !isWebview) {
		return authenticated ? <Navigate to="/" /> : <Outlet />;
	}
	if (type === "public" && isWebview) {
		return authenticated ? <Navigate to="/explore" /> : <Outlet />;
	}


	if (type === "private") {
		if (window?.webkit && !isWebview) {
			return authenticated ? <Outlet /> : <Navigate to="/login" />;
		}

		return authenticated ? <Outlet /> : <Navigate to="/login/intro" />;
	}

	return null;
};

export { AuthLayout };
