import { formatPrice } from "../../../utils/formatPrice";
import {
	type DemographicResponse,
	demographics,
} from "../../../services/demographics";
import { type KeyboardEvent, useEffect, useRef, useState } from "react";
import Chart, {
	type ChartData,
	type ChartOptions,
	type Plugin,
} from "chart.js/auto";

interface DemographicsProps {
	listingId: string;
}

const Demographics = ({ listingId }: DemographicsProps) => {
	const [demographicData, setDemographicData] = useState([
		{ label: "Population (2021):", value: "N/A" },
		{ label: "Average Household Income:", value: "N/A" },
		{ label: "Household Average Size:", value: "N/A" },
		{ label: "Average Age:", value: "N/A" },
		{ label: "Renters:", value: "N/A" },
		{ label: "Single:", value: "N/A" },
		{ label: "Not in Labour Force:", value: "N/A" },
	]);

	const [pieData, setPieData] = useState<{
		labels: string[];
		values: number[];
	}>({
		labels: [],
		values: [],
	});

	const chartRef = useRef<HTMLCanvasElement | null>(null);
	const chartInstance = useRef<Chart<"pie"> | null>(null);
	const [selectedOption, setSelectedOption] =
		useState<string>("Household Income");

	const backgroundColors = [
		"#7A942E",
		"#8DB255",
		"#5B8930",
		"#6B9362",
		"#407A52",
		"#3C7632",
		"#2E8B57",
		"#66CDAA",
		"#98FB98",
		"#00FA9A",
		"#2E8B57",
		"#006400",
		"#556B2F",
		"#8FBC8F",
		"#228B22",
	];

	useEffect(() => {
		const fetchDemographicData = async () => {
			try {
				const response: DemographicResponse = await demographics(listingId);

				const populationData =
					response?.counts?.find((item) => item.countName === "populationData")
						?.value || "N/A";
				const avgIncomeData =
					response?.counts?.find((item) => item.countName === "avgIncomeData")
						?.value || "N/A";
				const avgHouseholdSizeData =
					response?.counts?.find(
						(item) => item.countName === "avgHouseholdSizeData",
					)?.value || "N/A";
				const avgAgeData =
					response?.counts?.find((item) => item.countName === "avgAgeData")
						?.value || "N/A";
				const renterData =
					response?.percentages?.find(
						(item) => item.percentageName === "Renter",
					)?.value || "N/A";
				const singleData =
					response?.percentages?.find(
						(item) =>
							item.percentageName === "Not married and not living common-law",
					)?.value || "N/A";
				const notInLabour =
					response?.percentages?.find(
						(item) => item.percentageName === "Not in the labour force",
					)?.value || "N/A";

				setDemographicData([
					{
						label: "Population (2021):",
						value: formatPrice(populationData),
					},
					{
						label: "Average Household Income:",
						value: `$${formatPrice(avgIncomeData)}`,
					},
					{
						label: "Household Average Size:",
						value: avgHouseholdSizeData.toString(),
					},
					{ label: "Average Age:", value: avgAgeData.toString() },
					{ label: "Renters:", value: `${renterData}%` },
					{ label: "Single:", value: `${singleData}%` },
					{ label: "Not in Labour Force:", value: `${notInLabour}%` },
				]);
			} catch (error) {
				console.error("Error fetching demographic data:", error);
			}
		};

		fetchDemographicData();
	}, [listingId]);

	useEffect(() => {
		const fetchPieData = async () => {
			const response: DemographicResponse = await demographics(listingId);
			const optionMapping: Record<string, string> = {
				"Household Income": "Income",
				"Property Type": "Property",
				"Commute Method": "Commute",
				"Year Built": "Poc",
			};

			const selectedOptionKey = optionMapping[selectedOption] || selectedOption;
			const selectedPieData =
				response?.pies?.find((pie) => pie[0]?.pieName === selectedOptionKey) ||
				[];

			setPieData({
				labels: selectedPieData.map((item) => item.sliceName || ""),
				values: selectedPieData.map((item) => item.value || 0),
			});
		};

		fetchPieData();
	}, [listingId, selectedOption]);

	useEffect(() => {
		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		if (chartRef.current && pieData.labels.length > 0) {
			const data: ChartData<"pie"> = {
				datasets: [
					{
						data: pieData.values,
						backgroundColor: backgroundColors,
						borderColor: "#fff",
					},
				],
			};

			const config: ChartOptions<"pie"> = {
				maintainAspectRatio: false,
				layout: { padding: 30 },
				plugins: {
					legend: { display: false },
					tooltip: {
						callbacks: {
							label: (tooltipItem) => {
								const label = pieData.labels[tooltipItem.dataIndex];
								const value = pieData.values[tooltipItem.dataIndex];
								return `${label}: ${value}`;
							},
						},
					},
				},
			};

			const pieLabelLinePlugin: Plugin<"pie"> = {
				id: "pieLabelLinePlugin",
				afterDraw(chart) {
					const { ctx, chartArea } = chart;
					if (!chartArea) {
						return;
					}

					const cx = chart.getDatasetMeta(0).data[0]?.x;
					const cy = chart.getDatasetMeta(0).data[0]?.y;

					if (cx === undefined || cy === undefined) {
						return;
					}

					const totalValue = chart.data.datasets[0].data.reduce(
						(acc, value) => acc + (typeof value === "number" ? value : 0),
						0,
					);

					chart.data.datasets.forEach((_, i) => {
						chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
							const sliceValue = chart.data.datasets[0].data[index];
							const percentage = (sliceValue / totalValue) * 100;

							if (percentage < 5) {
								return;
							}

							const { x: a, y: b } = datapoint.tooltipPosition(true);
							const x = 2 * a - cx;
							const y = 2 * b - cy;
							const halfwidth = chartArea.width / 2;
							const halfheight = chartArea.height / 2;
							const xLine = x >= halfwidth ? x + 20 : x - 20;
							const yLine = y >= halfheight ? y + 20 : y - 20;
							const extraLine = x >= halfwidth ? 10 : -10;

							ctx.beginPath();
							ctx.moveTo(x, y);
							ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
							ctx.fill();
							ctx.moveTo(x, y);
							ctx.lineTo(xLine, yLine);
							ctx.lineTo(xLine + extraLine, yLine);
							ctx.strokeStyle = "black";
							ctx.stroke();
							ctx.font = "12px Arial";
							const textXPosition = x >= halfwidth ? "left" : "right";
							const plusFivePx = x >= halfwidth ? 5 : -5;
							ctx.textAlign = textXPosition as CanvasTextAlign;
							ctx.textBaseline = "middle";
							ctx.fillStyle = "black";
							ctx.fillText(
								`${chart.data.datasets[0].data[index]}%`,
								xLine + extraLine + plusFivePx,
								yLine,
							);
						});
					});
				},
			};

			chartInstance.current = new Chart(chartRef.current, {
				type: "pie",
				data,
				options: config,
				plugins: [pieLabelLinePlugin],
			});
		}

		return () => {
			chartInstance.current?.destroy();
		};
	}, [pieData]);

	const handleKeyDown = (
		event: KeyboardEvent<HTMLDivElement>,
		option: string,
	) => {
		if (event.key === "Enter" || event.key === " ") {
			setSelectedOption(option);
		}
	};

	const isDemographicDataAvailable = () => {
		return demographicData.every((item) => item.value !== "N/A");
	};

	if (!isDemographicDataAvailable()) {
		return null;
	}

	return (
		<div className="space-y-4">
			<div className="px-4 space-y-4">
				<h2 className="font-semibold text-lg">Demographics</h2>
				<div className="space-y-3 text-xs">
					{demographicData.map((item) => (
						<div
							key={item.label}
							className="flex items-center justify-between w-full"
						>
							<span className="font-medium">{item.label}</span>
							<span>{item.value}</span>
						</div>
					))}
				</div>
				<div className="flex flex-wrap gap-2">
					{[
						"Household Income",
						"Age",
						"Occupation",
						"Ethnicity",
						"Language",
						"Year Built",
						"Property Type",
						"Commute Method",
					].map((option) => (
						<div
							key={option}
							onClick={() => setSelectedOption(option)}
							onKeyDown={(e) => handleKeyDown(e, option)}
							className={`text-sm w-fit rounded-[8px] py-1.5 px-4 border flex items-center text-center justify-center cursor-pointer ${
								selectedOption === option
									? "bg-z-dark-moss-green text-white border-z-dark-moss-green"
									: "border-[#E1E0E3] text-[#676473]"
							}`}
						>
							{option}
						</div>
					))}
				</div>
			</div>
			<div className="w-full flex justify-center items-center">
				<canvas ref={chartRef} id="myChart" className="w-[280px]" />
			</div>
			<div className="flex flex-wrap gap-4 mt-4 justify-start px-4">
				{pieData.labels.map((label, index) => (
					<div key={label} className="flex items-center gap-2">
						<span
							style={{
								backgroundColor: backgroundColors[index],
								width: "10px",
								height: "10px",
								display: "inline-block",
								borderRadius: "50%",
							}}
						/>
						<span className="text-sm">{label}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export { Demographics };
