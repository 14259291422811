export const selectedCitiesCoordinates = {
  "Abitibi 70": [-79.414726, 43.797619],
  "Addington Highlands": [-77.31209380734036, 44.901670100000004],
  "Adelaide-Metcalfe": [-81.72601973303338, 42.9262365],
  "Adjala-Tosorontio": [-79.9421083123357, 44.1375186],
  "Admaston/Bromley": [-76.85531047812216, 45.4978051],
  Ajax: [-79.0208814, 43.8505287],
  "Akwesasne (Part) 59": [45.000502, -74.652089],
  "Alfred and Plantagenet": [-74.9852273331816, 45.5438263],
  "Algoma, Unorganized, North Part": [-88.561308, 44.039951],
  "Algonquin Highlands": [-78.8255514671391, 45.294673450000005],
  "Alnwick/Haldimand": [-78.04760303007441, 44.097441700000005],
  Amaranth: [-80.23353020711696, 43.980090450000006],
  Amherstburg: [-83.1086458, 42.1013315],
  Armour: [-79.4308512, 43.7439436],
  Armstrong: [-89.0373698, 50.302131],
  Arnprior: [-76.3543692, 45.433515],
  Aroland: [-86.95, 50.233333],
  "Arran-Elderslie": [-81.2000689260308, 44.398078350000006],
  "Ashfield-Colborne-Wawanosh": [-81.5977006731455, 43.8581039],
  "Asphodel-Norwood": [-78.01036153346413, 44.3366441],
  Assiginack: [-81.810439, 45.742986],
  Athens: [-75.952256, 44.625942],
  Atikokan: [-91.624603, 48.762526],
  "Attawapiskat 91A": [-82.427092, 52.92376],
  Augusta: [-75.63448418193349, 44.738986350000005],
  Aurora: [-79.467545, 43.99973],
  Aylmer: [-80.983658, 42.773227],
  Baldwin: [-79.3446276, 44.2627098],
  Bancroft: [-77.8537127, 45.0570769],
  Barrie: [-79.6901302, 44.3893208],
  Bayham: [-80.78797003717852, 42.75],
  "Bear Island 1": [-80.048712, 46.978759],
  "Bearskin Lake": [-90.9683392, 53.9256186],
  Beckwith: [-76.08243025484217, 45.087480049999996],
  Belleville: [-77.3852556, 44.1633264],
  "Big Grassy River 35G": [-94.3155315, 49.0681172],
  "Big Island Mainland 93": [-94.30332946777344, 49.084136962890625],
  Billings: [-82.24786430074482, 45.92405315],
  "Black River-Matheson": [-80.464681, 48.5354964],
  "Blandford-Blenheim": [-80.59863089020438, 43.2462916],
  "Blind River": [-82.95639, 46.188388],
  Bluewater: [-81.61287623114876, 43.4679793],
  Bonfield: [-79.1477157, 46.2323763],
  "Bonnechere Valley": [-77.13870302211139, 45.4561721],
  Bracebridge: [-79.310989, 45.041508],
  "Bradford West Gwillimbury": [-79.5647069, 44.1143279],
  Brampton: [-79.7599366, 43.685832],
  Brant: [-79.8387365, 43.367019],
  Brantford: [-80.2631733, 43.1408157],
  Brethour: [-79.57458908772568, 47.7189816],
  Brighton: [-77.7370071, 44.0422275],
  Brock: [-79.10016490761303, 44.347685049999995],
  Brockton: [-79.4400216, 43.6509173],
  Brockville: [-75.6843332, 44.5895925],
  "Brooke-Alvinston": [-81.8975070722232, 42.84477855],
  "Bruce Mines": [-83.792725, 46.302355],
  "Brudenell, Lyndoch and Raglan": [-77.4215204407824, 45.326926900000004],
  "Burk's Falls": [-79.408916, 45.6202244],
  Burlington: [-79.7966835, 43.3248924],
  "Burpee and Mills": [-82.5901847334502, 45.78152745],
  Caledon: [-79.9061036956765, 43.827060149999994],
  Callander: [-79.365234, 46.222603],
  Calvin: [-78.9064170705955, 46.2445872],
  Cambridge: [-80.3123023, 43.3600536],
  "Carleton Place": [-76.1465672, 45.1403366],
  Carling: [-80.5363924324735, 45.321908199999996],
  "Carlow/Mayo": [-77.63347135231105, 45.155557200000004],
  Casey: [-79.57456266261369, 47.63874625],
  Casselman: [-75.086746, 45.313529],
  "Cat Lake 63C": [-91.8260067, 51.7245896],
  "Cavan Monaghan": [-78.46896060471727, 44.190608],
  "Central Elgin": [-81.09197900531538, 42.77210175],
  "Central Frontenac": [-76.86227289203347, 44.71344325],
  "Central Huron": [-81.54332585139252, 43.6600733],
  "Central Manitoulin": [-82.43740680903242, 45.5484022],
  "Centre Hastings": [-77.44348674580543, 44.4170792],
  "Centre Wellington": [-80.37477355636713, 43.71809265],
  Chamberlain: [-79.95067992379931, 47.888509600000006],
  Champlain: [-74.64569874628573, 45.53628915],
  Chapleau: [-83.4018741, 47.845632],
  "Chapleau 74A": [-83.4036099, 47.8416702],
  "Chapleau Cree Fox Lake": [-83.4362796, 47.8216691],
  Chapple: [-93.98483229371752, 48.77050895],
  "Charlton and Dack": [-79.95287270162365, 47.7871265],
  "Chatham-Kent": [-82.238724675, 41.875],
  Chatsworth: [-80.8978758, 44.4560807],
  "Chief's Point No. 28": [-81.2751552, 44.6635009],
  "Chippewas of Rama First Nation": [-79.3503541, 44.6490701],
  "Christian Island 30A": [-80.1979579, 44.8329072],
  "Clarence-Rockland": [-75.2607533, 45.5247978],
  Clarington: [-78.65135738273909, 43.96868585],
  Clearview: [-80.06694727187954, 44.36827245],
  Cobalt: [-79.6856668, 47.3962288],
  Cobourg: [-78.1677784, 43.9595998],
  Cochrane: [-81.017303, 49.066668],
  "Cochrane, Unorganized, North Part": [-83.0326717, 50.2978627],
  "Cochrane, Unorganized, South East Part": [
    -80.18403625488281, 48.301116943359375,
  ],
  "Cochrane, Unorganized, South West Part": [
    -80.55066680908203, 48.36391830444336,
  ],
  Coleman: [-79.77299733482212, 47.3532824],
  Collingwood: [-80.2172379, 44.5027226],
  Conmee: [-89.68544527132289, 48.48303545],
  "Constance Lake 92": [-84.1382361, 49.8075614],
  Cornwall: [-74.728703, 45.0184417],
  "Couchiching 16A": [-93.3664833, 48.6369485],
  Cramahe: [-77.87121733431846, 44.0707335],
  "Curve Lake First Nation 35": [-78.3711557, 44.4684002],
  "Dawn-Euphemia": [-82.03907375849955, 42.693970500000006],
  Dawson: [-83.90004027956573, 46.3201113],
  "Deep River": [-77.487602, 46.101328],
  "Deer Lake": [-94.0669568, 52.6314358],
  Deseronto: [-77.049866, 44.195005],
  "Dokis 9": [-79.965875, 46.065773],
  Dorion: [-88.5611645, 48.7854029],
  "Douro-Dummer": [-78.10523481975095, 44.4502138],
  "Drummond/North Elmsley": [-76.21034565791723, 44.956898100000004],
  Dryden: [-92.835846, 49.785698],
  Dubreuilville: [-84.5510486, 48.349083],
  "Duck Lake 76B": [-76.9220971, 45.0772312],
  "Dutton/Dunwich": [-81.5026543, 42.6640129],
  "Dysart et al": [-78.40030815452491, 45.2007432],
  "Eagle Lake 27": [-78.5096741, 45.1291977],
  "East Ferris": [-79.2774755, 46.1839063],
  "East Garafraxa": [-80.26268041188766, 43.836453000000006],
  "East Gwillimbury": [-79.41190570379848, 44.1435081],
  "East Hawkesbury": [-74.48701550085873, 45.499031],
  "East Zorra-Tavistock": [-80.8137615, 43.235852],
  "Edwardsburgh/Cardinal": [-75.49699401204806, 44.8312292],
  "Elizabethtown-Kitley": [-75.80833311545453, 44.68248785],
  "Elliot Lake": [-82.652893, 46.373464],
  Emo: [-93.8339174, 48.6319778],
  Englehart: [-79.8733624, 47.8255744],
  "English River 21": [-90.9648585, 49.2269668],
  Enniskillen: [-78.7704573, 44.0154372],
  Erin: [-80.070076, 43.77506],
  Espanola: [-81.769867, 46.265341],
  Essa: [-79.8063686586334, 44.2344356],
  Essex: [-82.8215468, 42.1744783],
  Evanturel: [-79.82424204345253, 47.805214649999996],
  Faraday: [-77.8860372, 45.0484425],
  "Fauquier-Strickland": [-81.9423176723462, 49.27069655],
  "Fort Erie": [-78.918611, 42.91308],
  "Fort Frances": [-93.3967615, 48.6114425],
  "Front of Yonge": [-75.85293349059256, 44.50220295],
  "Frontenac Islands": [-76.46284762846446, 44.1535213],
  Gananoque: [-76.1626654, 44.3296206],
  Gauthier: [-79.82427448183662, 48.145037349999996],
  "Georgian Bluffs": [-81.00872535041186, 44.68872225],
  Georgina: [-79.34615813017496, 44.341595749999996],
  Gillies: [-89.70068306403613, 48.2754223],
  Goderich: [-81.7110949, 43.7430924],
  "Gordon/Barrie Island": [-82.611316978025, 45.92479745],
  "Gore Bay": [-82.4640868, 45.9167876],
  "Grand Valley": [-80.3152465, 43.8991217],
  Gravenhurst: [-79.373131, 44.91741],
  "Greater Madawaska": [-76.90345207193592, 45.2686929],
  "Greater Napanee": [-76.9505543, 44.2473883],
  "Greater Sudbury / Grand Sudbury": [-80.991211, 46.49272],
  Greenstone: [-86.9493168, 49.7188752],
  "Grey Highlands": [-80.46038959610259, 44.35654685],
  Grimsby: [-79.560677, 43.1931661],
  Guelph: [-80.2493276, 43.5460516],
  "Guelph/Eramosa": [-80.2567182345291, 43.5964593],
  "Haldimand County": [-79.8362031, 42.945542],
  "Halton Hills": [-80.017604, 43.64701],
  Hamilton: [-79.8728583, 43.2560802],
  Hanover: [-81.0279572, 44.1520712],
  Harris: [-94.5327072, 49.7466698],
  "Hastings Highlands": [-77.94982075983899, 45.237321449999996],
  "Havelock-Belmont-Methuen": [-77.90199109297498, 44.5788007],
  Hawkesbury: [-74.6055611, 45.610281],
  "Head, Clara and Maria": [-78.2865582, 46.2519874],
  Hearst: [-83.666382, 49.690732],
  "Highlands East": [-78.25811290039293, 44.9730785],
  Hilliard: [-94.5446243, 49.9288521],
  "Hilton Beach": [-83.888585, 46.256732],
  Hornepayne: [-84.7752864, 49.2181387],
  Horton: [-76.63231923391163, 45.507024099999995],
  Howick: [-81.06960637633733, 43.89460115],
  Hudson: [-92.1703196, 50.0904426],
  Huntsville: [-79.218434, 45.3263919],
  "Huron East": [-81.2916616011816, 43.622032000000004],
  "Huron Shores": [-83.3830444, 46.2998537],
  "Huron-Kinloss": [-81.54706912412098, 44.03441265],
  Ignace: [-91.654816, 49.41276],
  Ingersoll: [-80.88211, 43.037725],
  Innisfil: [-79.5461073, 44.3150892],
  "Iroquois Falls": [-80.6770998, 48.7672213],
  James: [-79.3727041, 43.6694032],
  Johnson: [-83.89664286296968, 46.35745175],
  Joly: [-79.24785085351627, 45.78857105],
  Kapuskasing: [-82.433333, 49.416667],
  "Kasabonika Lake": [-88.6078048, 53.5338547],
  "Kawartha Lakes": [-78.7421729, 44.3596825],
  Kearney: [-79.2240995, 45.5554837],
  Kenora: [-94.4893528, 49.766215],
  "Kenora, Unorganized": [-85.85237998667819, 52.9312176],
  Kerns: [-79.82412742596752, 47.62412605],
  "Killaloe, Hagarty and Richards": [-77.51786528645101, 45.6171152],
  Killarney: [-81.513062, 45.971197],
  Kincardine: [-81.6348713, 44.1776378],
  King: [-79.60477076446632, 44.0034771],
  Kingston: [-76.481323, 44.230687],
  Kingsville: [-82.7396606, 42.0378963],
  "Kirkland Lake": [-80.031281, 48.154177],
  Kitchener: [-80.4927815, 43.451291],
  "La Vallee": [-93.6281925, 48.615559],
  LaSalle: [-83.0606534, 42.2450635],
  "Lake of Bays": [-79.005432, 45.327048],
  Lakeshore: [-82.8189267, 42.3038299],
  "Lambton Shores": [-81.90202425000001, 43.19264175],
  "Lanark Highlands": [-76.48559826942638, 45.0875524],
  "Lansdowne House": [-87.8876724, 52.2187123],
  "Larder Lake": [-79.7192313, 48.0994228],
  Latchford: [-79.810181, 47.328585],
  "Laurentian Hills": [-80.4988723, 43.4199304],
  "Laurentian Valley": [-77.2679783426158, 45.748619149999996],
  Leamington: [-82.5996998, 42.0531166],
  "Leeds and the Thousand Islands": [-76.07103712045979, 44.46010075],
  Limerick: [-77.67331690822382, 44.904538599999995],
  Lincoln: [-79.41598053990981, 43.17354975],
  London: [-81.243372, 42.9832406],
  Loyalist: [-76.73314409366216, 44.20911845],
  "Lucan Biddulph": [-81.35890263616659, 43.21985325],
  "Macdonald, Meredith and Aberdeen Additional": [
    -83.97978706958693, 46.47937265,
  ],
  Machin: [-93.22489346654682, 49.792971949999995],
  "Madawaska Valley": [-77.73451756391728, 45.51659575],
  Madoc: [-77.50766798148976, 44.58180005],
  Magnetawan: [-79.6438541, 45.6651963],
  Malahide: [-80.898832, 42.729936],
  Manitouwadge: [-85.8384058, 49.121541],
  Mapleton: [-80.68926371473657, 43.717472099999995],
  Marathon: [-86.371598, 48.7209349],
  Markham: [-79.3376825, 43.8563707],
  "Markstay-Warren": [-80.49241648711632, 46.493508750000004],
  "Marmora and Lake": [-77.7239592234209, 44.6250121],
  Matachewan: [-80.6472976, 47.9394185],
  Mattawa: [-78.703308, 46.317296],
  Mattawan: [-78.82903493903595, 46.36902045],
  McDougall: [-80.03543657277305, 45.469355],
  McGarry: [-79.58429077237588, 48.14980105],
  McKellar: [-79.9192573, 45.507989],
  "McMurrich/Monteith": [-79.50272169364385, 45.4505894],
  "McNab/Braeside": [-76.50873172968235, 45.41323475],
  Meaford: [-80.5916531, 44.6069298],
  Melancthon: [-80.28444995806109, 44.14830965],
  "Merrickville-Wolford": [-75.84493933660211, 44.86532965],
  "Middlesex Centre": [-81.45454153776102, 43.0021908],
  Midland: [-79.885712, 44.750147],
  Milton: [-79.882817, 43.513671],
  "Minden Hills": [-78.72992711219575, 44.94724925],
  Minto: [-80.8476838, 43.8341324],
  Mississauga: [-79.6443879, 43.5896231],
  "Mississippi Mills": [-76.28995754439111, 45.2513293],
  Mono: [-80.1042319, 43.9462199],
  Montague: [-75.96802491266723, 44.9716881],
  Moonbeam: [-82.160043, 49.3438828],
  Moosonee: [-80.650635, 51.267071],
  Morley: [-94.1846180273129, 48.821937649999995],
  "Morris-Turnberry": [-81.27622605742624, 43.82187535],
  Mulmur: [-80.10235592975826, 44.1899502],
  "Muskoka Lakes": [-79.56598810161641, 45.1178146],
  "Nairn and Hyman": [-81.64546306236224, 46.373480799999996],
  Neebing: [-89.31265720006661, 48.1445509],
  Neskantaga: [-88.0194897, 52.2028003],
  "New Tecumseth": [-79.77443058053504, 44.0818996],
  Newbury: [-81.7993229, 42.684563],
  Newmarket: [-79.461708, 44.056258],
  "Niagara Falls": [-79.0639039, 43.1065603],
  "Niagara-on-the-Lake": [-79.0723264, 43.2556116],
  Nipigon: [-88.2610663, 49.0155918],
  Nipissing: [-79.5141664, 46.0955444],
  "North Algona Wilberforce": [-77.22219612966033, 45.607185],
  "North Bay": [-79.4607617, 46.3092115],
  "North Dumfries": [-80.3691519630711, 43.32297475],
  "North Dundas": [-75.37001535932451, 45.09082255],
  "North Frontenac": [-76.87963940318183, 44.98505955],
  "North Glengarry": [-74.67828381952614, 45.349324100000004],
  "North Grenville": [-75.64483041274728, 44.985842649999995],
  "North Huron": [-81.42655978902513, 43.8249172],
  "North Kawartha": [-78.14223038772805, 44.730762299999995],
  "North Middlesex": [-81.62632336803046, 43.152165],
  "North Perth": [-80.98352920005001, 43.691263199999995],
  "North Stormont": [-75.03346680176028, 45.216480950000005],
  "Northeastern Manitoulin and the Islands": [-81.9266062, 45.9810237],
  "Northern Bruce Peninsula": [-81.44689125142602, 45.13567595],
  Norwich: [-80.59756, 42.987699],
  Oakville: [-79.666672, 43.447436],
  "Oil Springs": [-82.126915, 42.7838172],
  "Oliver Paipoonge": [-89.53092193148214, 48.4023268],
  Opasatika: [-82.867126, 49.528774],
  Orangeville: [-80.0973754, 43.9193171],
  Orillia: [-79.4175587, 44.6092059],
  "Oro-Medonte": [-79.54891085886374, 44.5445701],
  Oshawa: [-78.8635324, 43.8975558],
  "Otonabee-South Monaghan": [-78.21510688550094, 44.2433463],
  Ottawa: [-75.6901106, 45.4208777],
  "Owen Sound": [-80.9430094, 44.5678105],
  "Parry Sound": [-80.0337392, 45.3436067],
  Peawanuck: [-85.424194, 55.011489],
  Pelee: [-82.65929076389756, 41.77866675],
  Pelham: [-79.2838481, 43.0445702],
  Pembroke: [-77.113541, 45.8260909],
  Penetanguishene: [-79.9339487, 44.7688035],
  Perry: [-76.9514809, 45.0108376],
  Perth: [-76.2487294, 44.8992423],
  "Perth East": [-80.97355978729148, 43.49483625],
  "Perth South": [-81.13646895712793, 43.3117134],
  Petawawa: [-77.2826645, 45.8984286],
  Peterborough: [-78.3199496, 44.3048009],
  Petrolia: [-82.149582, 42.882002],
  "Pic Mobert North": [-85.6125138, 48.6951839],
  "Pic Mobert South": [-85.6417748, 48.6896071],
  Pickering: [-79.090576, 43.835765],
  "Pickle Lake": [-90.1925284, 51.4648142],
  "Plummer Additional": [-83.74194109784614, 46.2723297],
  "Plympton-Wyoming": [-82.1529152, 43.0699736],
  "Point Edward": [-82.4132944, 42.997903],
  "Poplar Hill": [-94.301147, 52.1005961],
  "Port Colborne": [-79.2513905, 42.8862392],
  "Port Hope": [-78.2939704, 43.9515755],
  Powassan: [-79.3611147, 46.0814468],
  Prescott: [-75.5128981, 44.710417],
  Prince: [-75.7076759, 44.5694923],
  "Prince Edward County": [-77.246933, 44.031827],
  Puslinch: [-80.11377014121024, 43.47750295],
  "Quinte West": [-77.5748551, 44.0988246],
  "Rainy River": [-94.564819, 48.722679],
  "Rainy River, Unorganized": [-91.81935359027483, 48.53079165],
  Ramara: [-79.25539302515944, 44.6250669],
  "Red Lake": [-93.827362, 51.018938],
  "Red Rock": [-88.2592117, 48.9425393],
  Renfrew: [-76.6838617, 45.4720417],
  "Richmond Hill": [-79.4392925, 43.8801166],
  "Rideau Lakes": [-76.25709604054268, 44.6956662],
  Russell: [-75.3583264, 45.2573172],
  Ryerson: [-79.50846586215783, 45.5797934],
  "Sables-Spanish Rivers": [-81.95016328162131, 46.1814897],
  Sarnia: [-82.403533, 42.9743821],
  "Saugeen Shores": [-81.3713261, 44.4952483],
  "Sault Ste. Marie": [-84.320068, 46.52391],
  Schreiber: [-87.2627416, 48.8114595],
  Scugog: [-78.90598875892695, 44.1150554],
  Seguin: [-79.85287932874844, 45.321133599999996],
  Selwyn: [-78.36947993124312, 44.4262349],
  Severn: [-79.51491635139335, 44.7546878],
  Shelburne: [-80.203431, 44.078473],
  Shuniah: [-88.98890600865857, 48.55469745],
  "Sioux Lookout": [-92.19624322619077, 50.11345465],
  "Sioux Narrows-Nestor Falls": [-94.0888231837437, 49.3358934],
  "Slate Falls": [-91.6183735, 51.1498032],
  "Smiths Falls": [-76.0212062, 44.9013316],
  "Smooth Rock Falls": [-81.6311885, 49.2768],
  "South Algonquin": [-78.0849327710489, 45.493983400000005],
  "South Bruce": [-81.19888638182067, 44.0319668],
  "South Bruce Peninsula": [-81.268759, 44.6310905],
  "South Dundas": [-75.26477130603541, 44.94854975],
  "South Frontenac": [-76.48154805356438, 44.5403979],
  "South Glengarry": [-74.55400590796428, 45.1869835],
  "South Huron": [-81.52918448812369, 43.33238105],
  "South River": [-79.380341, 45.841238],
  "South Stormont": [-74.95146455617325, 45.063750150000004],
  "South-West Oxford": [-80.82551139448444, 42.97844085],
  Southgate: [-80.59655909894406, 44.09883325],
  "Southwest Middlesex": [-81.70352321815045, 42.74257325],
  Southwold: [-81.33191889735019, 42.7415823],
  Spanish: [-82.347307, 46.193239],
  Springwater: [-79.83172306039613, 44.49409275],
  "St. Catharines": [-79.2441003, 43.1579812],
  "St. Clair": [-82.35465521163846, 42.77821545],
  "St. Joseph": [-84.043291, 46.216055],
  "St. Marys": [-81.1421953, 43.2594556],
  "St. Thomas": [-81.1929882, 42.779022],
  "St.-Charles": [-80.417175, 46.363041],
  "Stirling-Rawdon": [-77.59914503994594, 44.375719450000005],
  "Stone Mills": [-76.93986514878858, 44.4582381],
  Stratford: [-80.9818016, 43.3700899],
  "Strathroy-Caradoc": [-81.55485268973419, 42.9064729],
  Strong: [-79.41451757738679, 45.7500048],
  Sundridge: [-79.39576851875972, 45.771078900000006],
  Tay: [-79.77099198066065, 44.7164408],
  "Tay Valley": [-76.41507177274269, 44.84882225],
  Tecumseh: [-82.8861974, 42.3117951],
  Tehkummah: [-82.014117, 45.656002],
  Temagami: [-80.07363445, 46.9736742],
  "Temiskaming Shores": [-79.675598, 47.512563],
  "Terrace Bay": [-87.102356, 48.782437],
  "Thames Centre": [-81.1401989, 43.1054531],
  "The Archipelago": [-80.26660787777658, 45.151736549999995],
  "The Blue Mountains": [-80.42957966752195, 44.6721655],
  "The Nation / La Nation": [-74.99865944814178, 45.38482985],
  "The North Shore": [-82.63579196669079, 46.0880249],
  Thessalon: [-83.5483478, 46.2638311],
  Thornloe: [-79.7600853, 47.6675948],
  Thorold: [-79.2005935, 43.1249127],
  "Thunder Bay": [-89.259796, 48.406414],
  "Thunder Bay, Unorganized": [-89.55741127010594, 49.3068222],
  Tillsonburg: [-80.7265015, 42.859494],
  Timmins: [-81.330414, 48.477473],
  Tiny: [-80.12709935653204, 44.7382893],
  Toronto: [-79.3839347, 43.6534817],
  "Trent Hills": [-77.82218148636545, 44.2921994],
  "Trent Lakes": [-78.42532426406586, 44.6630272],
  Tweed: [-77.3119649, 44.4764863],
  Tyendinaga: [-77.202437, 44.253617],
  Uxbridge: [-79.1226768, 44.1088502],
  "Val Rita-Harty": [-82.61997044863206, 49.4857064],
  Vaughan: [-79.5268023, 43.7941544],
  Wainfleet: [-79.3764231, 42.9238093],
  Warwick: [-81.9419095, 43.0043716],
  "Wasaga Beach": [-80.0203156, 44.5224813],
  Waterloo: [-80.5222961, 43.4652699],
  Wawa: [-84.7740199, 47.9929283],
  Wawakapewin: [-89.1421612, 53.4508307],
  Webequie: [-87.37771580431644, 52.98388275],
  Welland: [-79.2484192, 42.9922181],
  Wellesley: [-80.7645006, 43.4774985],
  "Wellington North": [-80.58398838813542, 43.91081545],
  "West Elgin": [-81.64382954485299, 42.567862000000005],
  "West Grey": [-80.88357696068783, 44.15537915],
  "West Lincoln": [-79.56673305523626, 43.0724658],
  "West Perth": [-81.25135702785215, 43.475014],
  Westport: [-76.397463, 44.678462],
  Whitby: [-78.9421751, 43.87982],
  "Whitchurch-Stouffville": [-79.2454446, 43.9717581],
  "White River": [-85.2844723, 48.5932963],
  Whitestone: [-80.05555377570082, 45.6857252],
  "Whitewater Region": [-76.8902669, 45.8197719],
  Wilmot: [-80.63520272121451, 43.39576635],
  Windsor: [-83.0373389, 42.3167397],
  Wollaston: [-77.8394652112776, 44.8610085],
  Woodstock: [-80.7562977, 43.1301111],
  Woolwich: [-80.49951537867625, 43.566549699999996],
  Zorra: [-80.95447503190499, 43.14419385],
};
