import { universalFetch } from "./common";
import { SelectedCitiesEnum } from "../selectedCities";

const mapValue = (value: string) => (value === "5+" ? 5 : Number(value));

export const transformFilterValues = (values: string[] | undefined) =>
  values && values.length > 0
    ? values.every((value) => value === "Any")
      ? null
      : values.map(mapValue)
    : undefined;

export const parseDaysOnMarket = (
  value: string | undefined | null
): DaysOnMarketFilterEnum | undefined => {
  switch (value) {
    case DaysOnMarketFilterEnum.ZeroToSevenDays:
    case DaysOnMarketFilterEnum.EightToFourteenDays:
    case DaysOnMarketFilterEnum.FifteenToThirtyDays:
    case DaysOnMarketFilterEnum.ThirtyPlusDays:
      return value;
    default:
      return undefined;
  }
};

export const mapStringToEnum = (cities: string[]): SelectedCitiesEnum[] => {
  return cities
    .map((city) => {
      const enumKey = Object.keys(SelectedCitiesEnum).find(
        (key) =>
          SelectedCitiesEnum[key as keyof typeof SelectedCitiesEnum] === city
      );
      return enumKey
        ? SelectedCitiesEnum[enumKey as keyof typeof SelectedCitiesEnum]
        : undefined;
    })
    .filter((city): city is SelectedCitiesEnum => city !== undefined);
};

export const parseBasement = (
  value: string | undefined | null
): BasementFilterEnum | undefined => {
  if (Object.values(BasementFilterEnum).includes(value as BasementFilterEnum)) {
    return value as BasementFilterEnum;
  }
  return undefined;
};

export const parseNumKitchen = (
  value: string | undefined | null
): NumberOfKitchensFilterEnum | undefined => {
  if (
    Object.values(NumberOfKitchensFilterEnum).includes(
      value as NumberOfKitchensFilterEnum
    )
  ) {
    return value as NumberOfKitchensFilterEnum;
  }
  return undefined;
};
export const parseStyle = (
  value: string | undefined | null
): StyleFilterEnum | undefined => {
  if (Object.values(StyleFilterEnum).includes(value as StyleFilterEnum)) {
    return value as StyleFilterEnum;
  }
  return undefined;
};

export const parseGarage = (
  value: string | undefined | null
): GarageFilterEnum | undefined => {
  if (Object.values(GarageFilterEnum).includes(value as GarageFilterEnum)) {
    return value as GarageFilterEnum;
  }
  return undefined;
};

interface SearchListingAddress {
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface SearchListingInterface {
  listingId: string;
  images?: string[];
  displayImageUrl?: string;
  listPrice: number;
  originalPrice: number;
  listDate: Date;

  lastStatus: string;
  numBathrooms: number;
  numBedrooms: number;
  sqft: number;
  downPaymentContribution?: number;
  liked: boolean;
  address: SearchListingAddress;
  map?: {
    type: string;
    coordinates: [number, number];
  };
  coordinates?: { lat: number; long: number };
  displayAddress?: string;
  propertyType: string;
}
export enum DaysOnMarketFilterEnum {
  ZeroToSevenDays = "0-7_DAYS",
  EightToFourteenDays = "8-14_DAYS",
  FifteenToThirtyDays = "15-30_DAYS",
  ThirtyPlusDays = "30+_DAYS",
}

export enum AgeOfPropertyFilterEnum {
  ZeroToNineYears = "0_9_YEARS",
  TenToFortyYears = "10_40_YEARS",
  FortyPlusYears = "40_PLUS_YEARS",
}

export enum StyleFilterEnum {
  OneHalfStorey = "ONE_HALF_STOREY",
  TwoStorey = "TWO_STOREY",
  TwoHalfStorey = "TWO_HALF_STOREY",
  ThreeStorey = "THREE_STOREY",
  Apartment = "APARTMENT",
  BacksplitThree = "BACKSPLIT_THREE",
  BacksplitFour = "BACKSPLIT_FOUR",
  BacksplitFive = "BACKSPLIT_FIVE",
  Bungalow = "BUNGALOW",
  Bungaloft = "BUNGALOFT",
  Chalet = "CHALET",
  Contemporary = "CONTEMPORARY",
  GardenHouse = "GARDEN_HOUSE",
  Log = "LOG",
  Loft = "LOFT",
  Other = "OTHER",
  SidesplitThree = "SIDESPLIT_THREE",
  SidesplitFour = "SIDESPLIT_FOUR",
  SidesplitFive = "SIDESPLIT_FIVE",
}

export enum SquareFootageFilterEnum {
  LessThanFiveHundred = "LESS_THAN_500",
  FiveHundredToElevenHundred = "500_1100",
  ElevenHundredToFifteenHundred = "1100_1500",
  FifteenHundredPlus = "1500_PLUS",
}

export enum BasementFilterEnum {
  Apartment = "APARTMENT",
  Unfinished = "UNFINISHED",
  Finished = "FINISHED",
}

export enum GarageFilterEnum {
  Attached = "ATTACHED",
  Detached = "DETACHED",
}

export enum NumberOfKitchensFilterEnum {
  One = "ONE",
  Two = "TWO",
  ThreePlus = "THREE_PLUS",
}
export interface ListingFiltersInterface {
  status?: string;
  selectedCities: SelectedCitiesEnum[] | null;
  numberOfBedrooms?: number[] | null;
  numberOfBathrooms?: number[] | null;
  numberOfParkingSpaces?: number[] | null;
  dens?: boolean | null;
  priceRange?: number[];
  propertyType?: string[] | null;
  daysOnMarket?: DaysOnMarketFilterEnum;
  ageOfProperty?: AgeOfPropertyFilterEnum;
  style?: StyleFilterEnum;
  basement?: BasementFilterEnum;
  sqft?: SquareFootageFilterEnum;
  garage?: GarageFilterEnum;
  numKitchens?: NumberOfKitchensFilterEnum;
}

export const fetchExploreListings = async (
  page?: string,
  limit?: string,
  sortBy?: string,
  sortOrder?: string,
  long?: number,
  lat?: number,
  zoom?: number,
  search?: string,
  filters?: ListingFiltersInterface,
  
): Promise<SearchListingInterface[]> => {
  try {
    const paramEntries: [string, string | number | undefined][] = [
      ["page", page],
      ["limit", limit],
      ["sortBy", sortBy],
      ["sortOrder", sortOrder],
      ["long", long],
      ["lat", lat],
      ["zoom", zoom],
      ["search", search],
    ];

    const params = new URLSearchParams(
      paramEntries.reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value.toString();
        }
        return acc;
      }, {} as Record<string, string>)
    );

    const url = `/listings?${params.toString()}`;

    const response = await universalFetch(url, "POST", { filters });
    const properties = response.listings as SearchListingInterface[];
    return properties;
  } catch (error) {
    console.error("Failed fetchExploreListings", error);
    throw error;
  }
};
