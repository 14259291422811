import { create } from "zustand";

interface MapState {
  mapLatitude: number | null;
  mapLongitude: number | null;
  mapZoom: number | null;
  mapGlZoom: number | null;
  setMapLatitude: (lat: number) => void;
  setMapLongitude: (lng: number) => void;
  setMapGlZoom: (zoom: number) => void;
  setMapZoom: (zoom: number) => void;
  setCoordinates: (lat: number, lng: number) => void;
  setMapState: (lat: number, lng: number, zoom: number) => void;
}

export const useMapStore = create<MapState>((set) => ({
  mapLatitude: null,
  mapLongitude: null,
  mapZoom: null,
  mapGlZoom: null,
  setMapLatitude: (lat) => set({ mapLatitude: lat }),
  setMapLongitude: (lng) => set({ mapLongitude: lng }),
  setMapGlZoom: (zoom) => set({ mapGlZoom: zoom }),
  setMapZoom: (zoom) => set({ mapZoom: zoom }),
  setCoordinates: (lat, lng) => set({ mapLatitude: lat, mapLongitude: lng }),
  setMapState: (lat, lng, zoom) =>
    set({ mapLatitude: lat, mapLongitude: lng, mapZoom: zoom }),
}));
