import { create } from "zustand";

interface PaginationStore {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pageStep: number;
  setPageStep: (page: number) => void;
  pageStepList: number;
  setPageStepList: (page: number) => void;
  resetPage: () => void;
}

const usePaginationStore = create<PaginationStore>((set) => ({
  currentPage: 1,
  setCurrentPage: (page) => set({ currentPage: page }),

  pageStep: 1,
  setPageStep: (page) => set({ pageStep: page }),
  pageStepList: 1,
  setPageStepList: (page) => set({ pageStepList: page }),
  resetPage: () => set({ currentPage: 1 }),
}));

export { usePaginationStore };
