import { Input } from "../common";
import { type ChangeEvent, useRef } from "react";
import { handleKeyPress } from "../../utils/keyboardUtils";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { useAdvertisementUserStore } from "../../stores/advertisementFlowStore";

const HowWeReachYouView = () => {
	const phoneNumberRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);

	const {
		email,
		phoneNumber,
		setEmail,
		setPhoneNumber,
		phoneNumberError,
		emailError,
	} = useAdvertisementUserStore();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		switch (name) {
			case "email": {
				setEmail(value.toLowerCase());
				break;
			}
			case "phoneNumber": {
				const formattedPhone = formatPhoneNumber(value.replace(/[^\d]/g, ""));
				setPhoneNumber(formattedPhone);
				break;
			}
			default:
				break;
		}
	};

	return (
		<div className="h-full w-full px-4 flex justify-center items-center">
			<div
				className="flex items-start flex-col w-full pb-4"
				onKeyDown={(e) => {
					if (e.target === emailRef.current) {
						handleKeyPress(e, emailRef);
					} else if (e.target === phoneNumberRef.current) {
						handleKeyPress(e, phoneNumberRef);
					}
				}}
			>
				<h2 className="text-z-shark text-2xl font-bold">
					How can we reach you?
				</h2>
				<div className="mt-8 w-full space-y-10">
					<Input
						ref={emailRef}
						inputType="email"
						name="email"
						value={email}
						autoComplete="email"
						onChange={handleChange}
						radius="20px"
						label="Email"
						isError={emailError}
						placeholder="Enter Email"
					/>
					<Input
						inputType="tel"
						ref={phoneNumberRef}
						autoComplete="tel"
						name="phoneNumber"
						value={phoneNumber}
						onChange={handleChange}
						isError={phoneNumberError}
						radius="20px"
						label="Phone Number"
						placeholder="Enter Phone Number"
					/>
				</div>
			</div>
		</div>
	);
};

export { HowWeReachYouView };
