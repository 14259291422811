import "./filterPanelStyles.css";
import { BarChart } from "./priceBarChart";
import { PriceSlider } from "./priceSlider";
import { FaPaintBrush } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DropdownSelector } from "./dropdownSelector";
import { MultiSelectGroup } from "../multiSelectGroup";
import xButtonIcon from "../../../assets/xButtonIcon.svg";
import { useNavbarStore } from "../../../stores/navBarStatus";
import { PropertyTypeSelector } from "./propertyTypeSelector";
import { PropertyTypeSelectGroup } from "../singleSelectGroup";
import { updatePreferences } from "../../../services/preferences";
import { useListingStore } from "../../../stores/exploreListings";
import { useUserProfileStore } from "../../../stores/profileStore";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import { propertyPreferences } from "../../../mocks/propertyPreferencesData";
import { BuyerMatchingPreferencesPropertyTypeEnum } from "../../../interfaces/buyerMatchingPreferences";
import {
	useFilterPanelStore,
	useMatchFiltersStore,
} from "../../../stores/filterStore";
import {
	type ReactNode,
	useState,
	type KeyboardEvent,
	useEffect,
	useRef,
} from "react";
import { fetchAccountData } from "../../../services/account";

interface FilterPanelProps {
	panelOpen: boolean;
	mapOpen?: boolean;
}

interface SectionContainerProps {
	title: string;
	children: ReactNode;
}

const HR = () => {
	return (
		<hr className="w-full mt-5 mb-5 justify-self-center border-t-1 border-gray-300" />
	);
};

const SectionContainer = ({ title, children }: SectionContainerProps) => {
	const bottomPadding = title === "Advanced Filters" ? "pb-20" : "pb-5";
	return (
		<div className={`flex flex-col w-full bg-transparent ${bottomPadding}`}>
			<div className="w-full flex justify-start mb-2">
				<h3 className="text-black font-bold text-lg">{title}</h3>
			</div>

			{children}
		</div>
	);
};

const mapEnumToLabel = (
	enumValue: BuyerMatchingPreferencesPropertyTypeEnum,
): string => {
	switch (enumValue) {
		case BuyerMatchingPreferencesPropertyTypeEnum.Detached:
			return "Detached";
		case BuyerMatchingPreferencesPropertyTypeEnum.SemiDetached:
			return "Semi-Detached";
		case BuyerMatchingPreferencesPropertyTypeEnum.Townhouse:
			return "Townhouse";
		case BuyerMatchingPreferencesPropertyTypeEnum.CondoTownhouse:
			return "Condo Townhouse";
		case BuyerMatchingPreferencesPropertyTypeEnum.Condo:
			return "Condo";
		case BuyerMatchingPreferencesPropertyTypeEnum.Apartment:
			return "Apartment";
		default:
			return "";
	}
};

export const mapPropertyTypeForPost = (
	propertyTypeLabel: string,
): BuyerMatchingPreferencesPropertyTypeEnum | null => {
	switch (propertyTypeLabel) {
		case "Detached":
			return BuyerMatchingPreferencesPropertyTypeEnum.Detached;
		case "Semi-Detached":
			return BuyerMatchingPreferencesPropertyTypeEnum.SemiDetached;
		case "Townhouse":
			return BuyerMatchingPreferencesPropertyTypeEnum.Townhouse;
		case "Condo Townhouse":
			return BuyerMatchingPreferencesPropertyTypeEnum.CondoTownhouse;
		case "Condo":
			return BuyerMatchingPreferencesPropertyTypeEnum.Condo;
		case "Apartment":
			return BuyerMatchingPreferencesPropertyTypeEnum.Apartment;
		default:
			return null;
	}
};

const FilterPanel = ({ panelOpen, mapOpen }: FilterPanelProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [openDropdownType, setOpenDropdownType] = useState<string | null>("");
	const [showError, setShowError] = useState<boolean>(false);
	const { userProfile, setUserProfile } = useUserProfileStore();
	const filterPanelRef = useRef<HTMLDivElement>(null);
	const { showNavbar, hideNavbar } = useNavbarStore();
	const { listings } = useListingStore();
	const {
		toggleFilterPanelVisible,
		hideFilterPanel,
		setFilterPanelOrigin,
		filterPanelOrigin,
	} = useFilterPanelStore();

	const navigate = useNavigate();

	const {
		setBeds,
		setBaths,
		setDens,
		setPropertyType,
		setParking,
		beds,
		baths,
		parking,
		dens,
		propertyType,
		daysOnMarket,
		style,
		basement,
		garageType,
		numberOfKitchens,
		clearAllFilters,
		setReloadMapCities,
	} = useMapFiltersStore();

	const {
		clearAllMatchFilters,
		matchBedCount,
		matchBathCount,
		matchParking,
		matchDenCount,
		matchPropertyType,
		setMatchPropertyType,
		setMatchBedCount,
		setMatchBathCount,
		setMatchParking,
		setMatchDenCount,
	} = useMatchFiltersStore();

	useEffect(() => {
		if (userProfile?.matchingPreferences?.filters) {
			const {
				numberOfBathrooms,
				numberOfBedrooms,
				numberOfParkingSpaces,
				dens,
				propertyType,
			} = userProfile.matchingPreferences.filters;

			setMatchBedCount(numberOfBedrooms?.map(String) ?? []);
			setMatchBathCount(numberOfBathrooms?.map(String) ?? []);
			setMatchParking(numberOfParkingSpaces?.map(String) ?? []);
			setMatchDenCount(dens ?? null);

			const propertyTypeLabels = propertyType?.map(mapEnumToLabel) ?? [];
			setMatchPropertyType((prevTypes) => propertyTypeLabels);
		}
	}, [
		userProfile,
		setMatchBedCount,
		setMatchBathCount,
		setMatchParking,
		setMatchDenCount,
		setMatchPropertyType,
	]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				filterPanelRef.current &&
				!filterPanelRef.current.contains(event.target as Node)
			) {
				hideFilterPanel();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [hideFilterPanel]);

	const filterPanelHeaderString =
		filterPanelOrigin === "searchField" ? "Search Filters" : "Matching Filters";

	const toggleDropdown = (
		type: string,
		e?: KeyboardEvent<HTMLButtonElement>,
	) => {
		if (!e || e.key === "Enter" || e.key === " ") {
			setOpenDropdownType((prev) => (prev === type ? null : type));
		}
	};

	const handleDrawer = () => {
		toggleFilterPanelVisible();
		if (mapOpen) {
			hideNavbar();
		}
	};

	useEffect(() => {
		if (filterPanelOrigin === "matching") {
			const fetchAndSetAccountData = async () => {
				try {
					const accountData = await fetchAccountData();
					setUserProfile(accountData);
				} catch (error) {
					console.error("Error fetching account data:", error);
				}
			};

			fetchAndSetAccountData();
		}
	}, [filterPanelOrigin, setUserProfile]);

	const handleCloseFilterPanel = () => {
		clearAllMatchFilters();
		hideFilterPanel();
		setFilterPanelOrigin("");
		showNavbar();
		if (mapOpen) {
			hideNavbar();
		}

		if (filterPanelOrigin === "searchField") {
			setReloadMapCities(true);
		}
	};

	const isSearchFilter = filterPanelOrigin === "searchField";

	const bedCount = isSearchFilter ? beds : matchBedCount;
	const setBedCount = isSearchFilter ? setBeds : setMatchBedCount;

	const bathCount = isSearchFilter ? baths : matchBathCount;
	const setBathCount = isSearchFilter ? setBaths : setMatchBathCount;

	const parkingCount = isSearchFilter ? parking : matchParking;
	const setParkingCount = isSearchFilter ? setParking : setMatchParking;

	const denCount = isSearchFilter ? dens : matchDenCount;
	const setDenCount = isSearchFilter ? setDens : setMatchDenCount;

	const handleButtonClick = async () => {
		setLoading(true);

		const mapValue = (value: string) => (value === "5+" ? 5 : Number(value));

		const numberOfBedrooms =
			matchBedCount && matchBedCount.length > 0
				? matchBedCount.every((value) => value === "Any")
					? null
					: matchBedCount.map(mapValue)
				: null;

		const numberOfBathrooms =
			matchBathCount && matchBathCount.length > 0
				? matchBathCount.every((value) => value === "Any")
					? null
					: matchBathCount.map(mapValue)
				: null;

		const numberOfParkingSpaces =
			matchParking && matchParking.length > 0
				? matchParking.every((value) => value === "Any")
					? null
					: matchParking.map(mapValue)
				: null;

		const mappedFilters = {
			numberOfBedrooms,
			numberOfBathrooms,
			numberOfParkingSpaces,
			dens: matchDenCount !== null ? matchDenCount : null,
			propertyType:
				matchPropertyType && matchPropertyType.length > 0
					? matchPropertyType
							.map(mapPropertyTypeForPost)
							.filter(
								(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
									pt !== null,
							)
					: null,
		};

		switch (true) {
			case filterPanelOrigin === "matching":
				updatePreferences(undefined, undefined, mappedFilters)
					.then(() => fetchAccountData())
					.then((accountData) => {
						if (accountData) {
							setUserProfile(accountData);
						}
						handleCloseFilterPanel();
						setLoading(false);
					})
					.catch((error) => {
						console.error("Error updating matching filters:", error);
						setLoading(false);
					});

				break;

			case filterPanelOrigin === "searchField": {
				setReloadMapCities(true);
				handleDrawer();
				break;
			}

			default:
				break;
		}
	};

	return (
		<div className="bg-transparent">
			<div
				ref={filterPanelRef}
				className={`filter-panel-base-style overflow-x-hidden ${
					panelOpen ? "transform translate-y-0" : "transform translate-y-full"
				}`}
			>
				<div className="filter-panel-header">
					<button
						type={"button"}
						className="back-button left-2"
						onClick={handleCloseFilterPanel}
					>
						<img src={xButtonIcon} alt={"close filter panel"} />
					</button>
					<div className="flex justify-center">
						<hr className="w-1/4 border-t-2 border-gray-300" />
					</div>
				</div>

				<div className="flex flex-col items-center pb-4 px-4">
					<div className="w-full text-center pb-6 pt-2">
						<h2 className="text-2xl font-semibold text-black">
							{filterPanelHeaderString}
						</h2>
					</div>
					{filterPanelOrigin === "matching" && (
						<button
							type="button"
							onClick={() => {
								let origin: string | null = "filter";

								if (filterPanelOrigin === "matching") {
									if (matchPropertyType.length === 0) {
										setShowError(true);
										origin = null;
									} else {
										setShowError(false);
										origin = "matchingFilter";
									}
								} else {
									setShowError(false);
								}

								if (origin !== null) {
									navigate("/select-city", { state: { origin } });
								}
							}}
							className="lrg-selector-button mb-2"
						>
							<FaPaintBrush className="text-white w-8 h-8" />

							<div className="text-sm text-white font-bold mt-2">
								Location & Advanced Filters
							</div>
						</button>
					)}

					<div className="space-y-10 mt-2">
						<MultiSelectGroup
							gap="12px"
							selectedValue={bedCount}
							label="Beds"
							onChange={(values) => {
								const updatedValues = values.map((value) =>
									value === "5+" ? "5" : value,
								);
								setBedCount(updatedValues);
							}}
							firstClass={true}
							options={propertyPreferences.beds.map((item) => ({
								label: item.value === 5 ? "5+" : item.label,
								value: String(item.value === null ? "Any" : item.value),
							}))}
							customClassName="text-[#B8B8B8]"
						/>

						<MultiSelectGroup
							gap="12px"
							selectedValue={bathCount}
							label="Bathrooms"
							onChange={(values) => {
								const updatedValues = values.map((value) =>
									value === "5+" ? "5" : value,
								);
								setBathCount(updatedValues);
							}}
							firstClass={true}
							options={propertyPreferences.beds.map((item) => ({
								label: item.value === 5 ? "5+" : item.label,
								value: String(item.value === null ? "Any" : item.value),
							}))}
							customClassName="text-[#B8B8B8]"
						/>

						<MultiSelectGroup
							gap="12px"
							selectedValue={parkingCount}
							label="Parking"
							onChange={(values) => {
								const updatedValues = values.map((value) =>
									value === "5+" ? "5" : value,
								);
								setParkingCount(updatedValues);
							}}
							firstClass={true}
							options={propertyPreferences.beds.map((item) => ({
								label: item.value === 5 ? "5+" : item.label,
								value: String(item.value === null ? "Any" : item.value),
							}))}
							customClassName="text-[#B8B8B8]"
						/>
						<PropertyTypeSelectGroup
							gap="12px"
							selectedValue={denCount}
							label="Dens"
							onChange={setDenCount}
							options={propertyPreferences.dens.map((item) => item)}
							customClassName="text-[#B8B8B8]"
							width="90px"
						/>
					</div>

					<HR />

					<SectionContainer title={"Property Type"}>
						<PropertyTypeSelector origin={filterPanelOrigin} />
					</SectionContainer>

					{filterPanelOrigin === "searchField" && (
						<>
							<HR />

							<div className="filter-panel-section-header">
								<h3 className="text-black font-bold text-lg ">Price Range</h3>
							</div>

							{/* <div className="w-full">
								<BarChart listings={listings} />
							</div> */}

							<PriceSlider min={0} max={2000000} />

							<HR />

							<SectionContainer title={"Preferences"}>
								<DropdownSelector
									title={"Days On Market"}
									openType={"daysOnMarket"}
									openDropdownType={openDropdownType}
									dropdownValue={daysOnMarket}
									toggleDropdown={toggleDropdown}
								/>

								{/* <DropdownSelector
									title={"Age Of Property"}
									openType={"ageOfProperty"}
									openDropdownType={openDropdownType}
									dropdownValue={ageOfProperty}
									toggleDropdown={toggleDropdown}
								/> */}

								<DropdownSelector
									title={"Style"}
									openType={"style"}
									openDropdownType={openDropdownType}
									dropdownValue={style}
									toggleDropdown={toggleDropdown}
								/>

								<DropdownSelector
									title={"Basement"}
									openType={"basement"}
									openDropdownType={openDropdownType}
									dropdownValue={basement}
									toggleDropdown={toggleDropdown}
								/>

								{/* <DropdownSelector
									title={"Sq Ft"}
									openType={"sqFt"}
									openDropdownType={openDropdownType}
									dropdownValue={sqft}
									toggleDropdown={toggleDropdown}
								/> */}

								<DropdownSelector
									title={"Garage Type"}
									openType={"garage"}
									openDropdownType={openDropdownType}
									dropdownValue={garageType}
									toggleDropdown={toggleDropdown}
								/>

								<DropdownSelector
									title={"Number of Kitchens"}
									openType={"kitchen"}
									openDropdownType={openDropdownType}
									dropdownValue={numberOfKitchens}
									toggleDropdown={toggleDropdown}
								/>
							</SectionContainer>
						</>
					)}
				</div>

				<div
					className="filter-panel-footer z-10"
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{filterPanelOrigin === "searchField" && (
						<span
							className="text-black underline"
							onClick={clearAllFilters}
							onKeyDown={clearAllFilters}
						>
							Clear All
						</span>
					)}

					{filterPanelOrigin !== "searchField" && <span />}

					<button
						type="button"
						className="filter-show-listings-button disabled:bg-[#F4F4F4] disabled:text-[#6B6B6B]"
						onClick={handleButtonClick}
						disabled={loading}
					>
						<span>
							{filterPanelOrigin === "searchField"
								? "Apply Filters"
								: "Update Filters"}
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export { FilterPanel };
