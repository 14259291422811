import { Analysis } from "./Analysis/analysis";
import { LisitngImages } from "./listingImages";
import { GetPreApproved } from "./getPreApproved";
import { LoadingCircle } from "../common/loading";
import { fetchGraph } from "../../services/graphs";
import LikedIcon from "../../assets/likedIcon.svg";
import { useState, useRef, useEffect } from "react";
import { reactions } from "../../services/reactions";
import { useAuth } from "../../context/authProvider";
import { fetchListing } from "../../services/listing";
import UnlikedIcon from "../../assets/unlikedIcon.svg";
import type { Listing } from "../../interfaces/listing";
import { AboutMarket } from "./AboutMarket/aboutMarket";
import PassIcon from "../../assets/Matching/passIcon.svg";
import LikeIcon from "../../assets/Matching/likeIcon.svg";
import { CartIcon } from "../../assets/BottomBar/CartIcon";
import ListingXbutton from "../../assets/listingXButton.svg";
import { useUserProfileStore } from "../../stores/profileStore";
import { PropertyDetails } from "./PropertyDetails/propertyDetails";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import type { StatisticsResponse } from "../../interfaces/statistics";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DownPaymentAssistanceBreakDown } from "./downPaymentAssistanceBreakDown";
import { NeighbourhoodInformation } from "./NeighbourhoodInformation/neighbourhoodInformation";
import CheckIconQual from "../../assets/checkIconQual.svg";

const ListingView = () => {
	const { zlid } = useParams<{ zlid: string }>();
	const { isWebview } = useAuth();
	const navigate = useNavigate();
	const [listing, setListing] = useState<Listing | null>(null);
	const [loading, setLoading] = useState(true);
	const hasFetchedData = useRef(false);
	const buttonContainerRef = useRef<HTMLDivElement | null>(null);
	const [buttonContainerHeight, setButtonContainerHeight] = useState(0);
	const location = useLocation();
	const origin = location.state?.origin;
	const [statistics, setStatistics] = useState<StatisticsResponse | null>(null);
	const [inCart, setInCart] = useState(false);
	const [addedToCart, setAddedToCart] = useState(false);
	const [liked, setLiked] = useState(false);
	const { userProfile } = useUserProfileStore();
	const [buttonDisabled, setButtonDisabled] = useState(false);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setListing(null);
		setStatistics(null);
		setLoading(true);
		hasFetchedData.current = false;
	}, [zlid]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (buttonContainerRef.current) {
			setButtonContainerHeight(buttonContainerRef.current.offsetHeight);
		}
	}, [loading]);

	useEffect(() => {
		const fetchListingData = async () => {
			if (hasFetchedData.current || !zlid) {
				return;
			}

			const timeoutId = setTimeout(() => {
				navigate(-1);
			}, 30000);

			try {
				setLoading(true);
				if (!isWebview) {
					await reactions(zlid, "OPEN_LISTING_DETAILS");
				}

				const reqs = await Promise.all([fetchListing(zlid)]);
				const fetchResult = reqs[0];
				setListing(fetchResult);

				setLoading(false);
				hasFetchedData.current = true;
				clearTimeout(timeoutId);
			} catch (error) {
				console.error("Failed to fetch listing data", error);
				setListing(null);
				clearTimeout(timeoutId);
				navigate(-1);
			}
		};

		fetchListingData();
	}, [zlid, navigate, isWebview]);

	useEffect(() => {
		const fetchStatistics = async () => {
			if (hasFetchedData.current || !zlid) {
				return;
			}
			try {
				const response = await fetchGraph(zlid);
				setStatistics(response);
			} catch (error) {
				console.error("Failed to fetch Statistics data", error);
			}
		};

		fetchStatistics();
	}, [zlid]);

	const handlePass = async () => {
		if (!zlid) {
			console.error("ZLID is undefined");
			return;
		}

		try {
			await reactions(zlid, "SWIPE_LEFT_TO_DISLIKE_LISTING");
			navigate(-1);
		} catch (error) {
			console.error("Failed to record left swipe reaction", error);
			navigate(-1);
		}
	};

	const handleLike = async () => {
		if (!zlid) {
			console.error("ZLID is undefined");
			return;
		}

		try {
			const response = await reactions(zlid, "SWIPE_RIGHT_TO_LIKE_LISTING");

			if (response?.inCart) {
				setInCart(response?.inCart);
				setAddedToCart(!response?.inCart);
				return;
			}

			navigate(-1);
		} catch (error) {
			console.error("Failed to record right swipe reaction", error);
			navigate(-1);
		}
	};
	useEffect(() => {
		const isLiked = userProfile?.likedHomes?.some(
			(home) => home.listingId === zlid,
		);
		setLiked(!!isLiked);
	}, [userProfile, zlid]);

	const handleAddtoCart = async () => {
		if (!zlid) {
			console.error("ZLID is undefined");
			return;
		}

		try {
			setButtonDisabled(true);
			const response = await reactions(zlid, "SWIPE_RIGHT_TO_LIKE_LISTING");
			setButtonDisabled(false);
			if (response?.inCart) {
				setInCart(true);
				return;
			}

			setAddedToCart(true);
		} catch (error) {
			console.error("Failed to add to cart", error);
		}
	};

	const handleAddToSavedProperties = async () => {
		if (!zlid) {
			console.error("ZLID is undefined");
			return;
		}
		try {
			await reactions(zlid, "SAVE_LISTING");

			if (liked) {
				await reactions(zlid, "REMOVE_SAVED_LISTING");
				setLiked(false);
			} else if (!liked) {
				await reactions(zlid, "SAVE_LISTING");
				setLiked(true);
			}
		} catch (error) {
			console.error("Failed to add to cart", error);
		}
	};
	return (
		<div
			className={`flex flex-col ${loading && "h-screen"} text-black relative  ${
				window.bridge ? " pb-bridge-inset-bottom" : "pb-6"
			}`}
			style={{
				paddingBottom: `${
					buttonContainerHeight +
					(window.bridge?.adjustedContentInset.bottom ?? 0) +
					30
				}px`,
			}}
		>
			<div className={`w-full ${loading && "h-full"} space-y-6`}>
				{loading && (
					<div className="h-full flex justify-center items-center">
						<LoadingCircle />
					</div>
				)}
				{listing && !loading && (
					<>
						<div className="relative">
							<button
								type="button"
								className={`fixed top-0 z-50 ${
									window.bridge ? "pt-bridge-inset-top" : "pt-2"
								} w-[100px] h-[100px] flex items-start drop-shadow-md justify-end z-20 pr-2 right-0`}
								onClick={() => navigate(-1)}
							>
								<img src={ListingXbutton} alt="" className="w-10 h-10" />
							</button>
							<LisitngImages
								images={listing.images}
								zlid={listing.listingId}
								listPrice={listing.listPrice}
								address={listing.address}
								soldDate={listing.soldDate ? listing.soldDate : undefined}
								soldPrice={listing.soldPrice ? listing.soldPrice : undefined}
								bed={listing.bed}
								bath={listing.bath}
								sqft={listing.sqft}
								propertyType={listing.propertyType}
								listDate={listing.listDate}
								downPaymentContribution={convertToFormattedAmount(
									listing.zownContribution,
								)}
							/>
						</div>
						<div className="px-2 space-y-6">
							{!listing.soldPrice && (
								<DownPaymentAssistanceBreakDown
									minDownPaymentNeeded={convertToFormattedAmount(
										listing.minDownPaymentNeeded,
									)}
									newDownPaymentNeeded={convertToFormattedAmount(
										listing.newDownPaymentNeeded,
									)}
									downPaymentContribution={convertToFormattedAmount(
										listing.zownContribution,
									)}
								/>
							)}

							<PropertyDetails listing={listing} statistics={statistics} />
							{!isWebview && <GetPreApproved />}
							<Analysis listing={listing} statistics={statistics} />
							<NeighbourhoodInformation listing={listing} />
							<AboutMarket listing={listing} statistics={statistics} />
						</div>
					</>
				)}
			</div>
			{!isWebview && (
				<>
					{!loading && origin === "matchingListing" && (
						<div
							ref={buttonContainerRef}
							className={`fixed flex w-full justify-center space-x-4 z-30 ${
								window.bridge ? "bottom-bridge-inset-bottom" : "bottom-4"
							}`}
						>
							<button
								type="button"
								className="drop-shadow-lg w-[85px] h-[85px] rounded-full"
								onClick={handlePass}
							>
								<img src={PassIcon} alt="Pass" />
							</button>
							<button
								type="button"
								className="drop-shadow-lg w-[85px] h-[85px] rounded-full"
								onClick={handleLike}
							>
								<img src={LikeIcon} alt="Like" />
							</button>
						</div>
					)}
					{!loading && origin !== "matchingListing" && (
						<div
							ref={buttonContainerRef}
							className={`fixed flex items-center gap-2 w-full justify-center px-6 z-30 ${
								window.bridge ? "bottom-bridge-inset-bottom" : "bottom-4"
							}`}
						>
							<button
								onClick={handleAddtoCart}
								type="button"
								disabled={buttonDisabled}
								className={`${inCart || addedToCart ? "bg-z-abbey text-black" : "bg-z-dark-moss-green text-white"} shadow-lg disabled:bg-[#D9D9D9] flex items-center gap-2 justify-center py-5 font-bold w-full rounded-full`}
							>
								<CartIcon
									fill={inCart || addedToCart ? "#32302F" : "#FFFFFF"}
								/>
								{inCart
									? "Already added to showing cart"
									: addedToCart
										? "Added to showing cart"
										: "Add to showing cart"}
							</button>
							<button
								type="button"
								className="rounded-full drop-shadow-lg"
								onClick={handleAddToSavedProperties}
							>
								<img src={liked ? LikedIcon : UnlikedIcon} alt="" />
							</button>
						</div>
					)}
				</>
			)}
			{isWebview && (
				<div
					ref={buttonContainerRef}
					className={`fixed flex items-center gap-2 w-full justify-center px-6 z-30 ${
						window.bridge ? "bottom-bridge-inset-bottom" : "bottom-4"
					}`}
				>
					<button
						onClick={() =>
							window.open("https://zown.ca/buyer/down-payment-qualification")
						}
						type="button"
						className="bg-z-dark-moss-green text-white shadow-lg disabled:bg-[#D9D9D9] flex items-center gap-2 justify-center py-5 font-bold w-full rounded-full"
					>
						<img src={CheckIconQual} alt="" />
						Get Qualified
					</button>
				</div>
			)}
		</div>
	);
};

export { ListingView };
