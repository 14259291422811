import { applyTransform } from "./applyTransform";

export const handlePan = (event: HammerInput, el: HTMLElement) => {
	el.classList.add("moving");

	if (event.deltaX === 0 || (event.center.x === 0 && event.center.y === 0)) {
		return;
	}

	const xMulti = event.deltaX * 0.03;
	const yMulti = event.deltaY / 80;
	const rotate = xMulti * yMulti;
	applyTransform(el, event.deltaX, event.deltaY, rotate);

	const loveLabel = el.querySelector<HTMLElement>(".love-label");
	const nopeLabel = el.querySelector<HTMLElement>(".nope-label");
	const opacity = Math.min(Math.abs(event.deltaX) / 100, 1);

	if (event.deltaX > 0) {
		if (loveLabel) {
			loveLabel.style.opacity = `${opacity}`;
		}
		if (nopeLabel) {
			nopeLabel.style.opacity = "0";
		}
	} else {
		if (nopeLabel) {
			nopeLabel.style.opacity = `${opacity}`;
		}
		if (loveLabel) {
			loveLabel.style.opacity = "0";
		}
	}
};

export const handlePanEnd = (
	event: HammerInput,
	el: HTMLDivElement,
	initCards: () => void,
	onSwipeLeft: (card: HTMLDivElement) => void,
	onSwipeRight: (card: HTMLDivElement) => void,
) => {
	el.classList.remove("moving");

	const loveLabel = el.querySelector<HTMLDivElement>(".love-label");
	const nopeLabel = el.querySelector<HTMLDivElement>(".nope-label");

	// Reset label opacity when pan ends
	if (loveLabel) {
		loveLabel.style.opacity = "0";
	}
	if (nopeLabel) {
		nopeLabel.style.opacity = "0";
	}

	const moveOutWidth = document.body.clientWidth;
	const shouldKeep =
		Math.abs(event.deltaX) < moveOutWidth / 3 &&
		Math.abs(event.velocityX) < 0.5;

	if (shouldKeep) {
		el.style.transition = "transform 0.3s ease";
		applyTransform(el, 0, 0, 0);
		const handleTransitionEnd = () => {
			el.style.transition = "";
			el.removeEventListener("transitionend", handleTransitionEnd);
		};
		el.addEventListener("transitionend", handleTransitionEnd);
	} else {
		const endX = Math.max(
			Math.abs(event.velocityX) * moveOutWidth,
			moveOutWidth,
		);
		const toX = event.deltaX > 0 ? endX : -endX;
		const toY = event.deltaY > 0 ? event.deltaY : -event.deltaY;
		const xMulti = event.deltaX * 0.03;
		const yMulti = event.deltaY / 80;
		const rotate = xMulti * yMulti;

		applyTransform(el, toX, toY, rotate);
		const handleTransitionEnd = () => {
			if (el.parentElement) {
				el.remove();
			}
			initCards();
			el.removeEventListener("transitionend", handleTransitionEnd);
		};
		el.addEventListener("transitionend", handleTransitionEnd);
	}

	el.classList.toggle("removed", !shouldKeep);

	if (!shouldKeep) {
		if (event.deltaX > 0) {
			onSwipeRight(el);
		} else {
			onSwipeLeft(el);
		}
	}
};
