import CondoIcon from "../../../assets/icons/condoIcon.svg";
import DetachedIcon from "../../../assets/icons/detachedIcon.svg";
import { useMatchFiltersStore } from "../../../stores/filterStore";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import CondoTownIcon from "../../../assets/icons/condoTownIcon.svg";
import TownhouseIcon from "../../../assets/icons/townhouseIcon.svg";
import SemiDetachedIcon from "../../../assets/icons/semiDetachedIcon.svg";
import OtherIcon from "../../../assets/icons/otherBuildingIcon.svg";

import "./filterPanelStyles.css";

interface PropertyTypeSelectorProps {
	origin: string;
}

export const propertyTypeLabels = [
	{ label: "Detached", icon: DetachedIcon },
	{
		label: "Semi-Detached",
		icon: SemiDetachedIcon,
	},
	{ label: "Townhouse", icon: TownhouseIcon },
	{
		label: "Condo Townhouse",
		icon: CondoTownIcon,
	},
	{ label: "Condo", icon: CondoIcon },
	{ label: "Apartment", icon: OtherIcon },
];

function PropertyTypeSelector({ origin }: PropertyTypeSelectorProps) {
	const { propertyType, setPropertyType } = useMapFiltersStore();
	const { matchPropertyType, setMatchPropertyType } = useMatchFiltersStore(
		(state) => state,
	);

	const handlePropertyTypes = (label: string) => {
		const propertyLabel = label as string;

		const isSearchField = origin === "searchField";
		const setTypes = isSearchField ? setPropertyType : setMatchPropertyType;

		setTypes((prevTypes: string[]) => {
			const updatedTypes = prevTypes.includes(propertyLabel)
				? prevTypes.filter((item) => item !== propertyLabel)
				: [...prevTypes, propertyLabel];

			return updatedTypes;
		});
	};

	return (
		<div className="grid grid-cols-2 gap-4">
			{propertyTypeLabels.map((property) => (
				<div
					key={property.label}
					className={`property-type-selector ${
						(origin === "searchField"
							? propertyType
							: matchPropertyType
						).includes(property.label)
							? "property-type-selected"
							: "property-type-unselected"
					}`}
					onClick={() => handlePropertyTypes(property.label)}
					onKeyDown={() => handlePropertyTypes(property.label)}
				>
					<div className="absolute top-2 left-2">
						<img
							src={property.icon}
							className="w-10 h-10"
							alt="Building Icon"
							style={{
								filter: (origin === "searchField"
									? propertyType
									: matchPropertyType
								).includes(property.label)
									? "invert(1) grayscale(1) contrast(100%)"
									: "invert(0) grayscale(0) contrast(100%)",
							}}
						/>
					</div>

					<div className="absolute bottom-2 left-2 font-semibold">
						{property.label}
					</div>
				</div>
			))}
		</div>
	);
}

export { PropertyTypeSelector };
