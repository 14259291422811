/**
 * Formats a string of digits into a standard US phone number format.
 * If the phone number is longer than 10 digits and starts with a "1",
 * the leading "1" is removed before formatting.
 *
 * @param {string} value - The raw phone number string which may include non-digit characters.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (value: string) => {
	// Remove all non-digit characters
	let numbers = value.replace(/[^\d]/g, "");

	// If the phone number is longer than 10 digits and starts with "1", remove the leading "1"
	if (numbers.length > 10 && numbers.startsWith("1")) {
		numbers = numbers.slice(1);
	}

	// Ensure the phone number is not longer than 10 digits
	const phoneNumber = numbers.slice(0, 10);

	// Extract area code, middle three, and last four digits
	const areaCode = phoneNumber.slice(0, 3);
	const middleThree = phoneNumber.slice(3, 6);
	const lastFour = phoneNumber.slice(6, 10);

	// Format the phone number based on its length
	let formattedNumber = "";
	if (phoneNumber.length > 6) {
		formattedNumber = `(${areaCode}) ${middleThree}-${lastFour}`;
	} else if (phoneNumber.length > 3) {
		formattedNumber = `(${areaCode}) ${middleThree}`;
	} else if (phoneNumber.length > 0) {
		formattedNumber = `(${areaCode}`;
	}

	return formattedNumber;
};
