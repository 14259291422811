import { create } from "zustand";
import type { MainProfile } from "../interfaces/mainProfile";
import { persist, createJSONStorage } from "zustand/middleware";

interface UserProfileStore {
	userProfile: MainProfile | null;
	setUserProfile: (profile: MainProfile) => void;
	clearUserProfile: () => void;
}

const useUserProfileStore = create<UserProfileStore>()(
	persist(
		(set) => ({
			userProfile: null,
			setUserProfile: (profile: MainProfile) => set({ userProfile: profile }),
			clearUserProfile: () => set({ userProfile: null }),
		}),
		{
			name: "user-profile-storage",
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export { useUserProfileStore };
