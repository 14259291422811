import { MainLayout } from "./layouts/main";
import { LoginLayout } from "./layouts/login";
import { AuthLayout } from "./layouts/authLayout";
import { ErrorView } from "./components/errorView";
import type { RouteObject } from "react-router-dom";
import { CartView } from "./components/CartView/cartView";
import { SubRouteLayout } from "./layouts/subRouteLayout";
import { BuyerInputFlow } from "./layouts/buyerInputFlow";
import { LifeStyle } from "./components/LifeStyle/lifeStyle";
import { FinanceView } from "./components/Finances/financeView";
import { ImagesView } from "./components/ImagesView/imagesView";
import { Prioritize } from "./components/Prioritize/prioritize";
import { NeedsWants } from "./components/NeedsWants/needsWants";
import { AdvertisementFlow } from "./layouts/advertisementLayout";
import { WelcomeView } from "./components/WelcomeView/welcomeViw";
import { DebtView } from "./components/AdvertisementView/debtView";
import { ListingView } from "./components/ListingView/listingView";
import { ShowingView } from "./components/ShowingView/showingView";
import { ProfileView } from "./components/ProfileView/profileView";
import { IntroView } from "./components/AdvertisementView/introView";
import { ContractView } from "./components/ContractView/contractView";
import { IncomeView } from "./components/AdvertisementView/incomeView";
import { SavingView } from "./components/AdvertisementView/savingView";
import { LifestyleView } from "./components/LifestyleView/lifestyleView";
import { BuyerMainView } from "./components/BuyerMainView/buyerMainView";
import { LikedHomesView } from "./components/LikedHomesView/likedHomesView";
import { LoginEmailView } from "./components/LoginEmailView/loginEmailView";
import { PointsInterest } from "./components/PointsInterest/pointsInterest";
import { CartComparison } from "./components/CartComparison/cartComparison";
import { PrioritiesView } from "./components/PrioritiesView/prioritiesView";
import { SavingInputView } from "./components/SavingInputView/savingInputView";
import { LoginVerifyView } from "./components/LoginVerifyView/loginVerifyView";
import { BuyerSearchView } from "./components/BuyerSearchView/buyerSearchView";
import { DeleteAccountMissYouView } from "./components/deleteAccountMissYouView";
import { DetailsInputView } from "./components/DetailsInputView/detailsInputView";
import { NeedsVsWantsView } from "./components/NeedsVsWantsView/needsVsWantsView";
import { NotificationView } from "./components/NotificationView/notificationView";
import { DeleteAccountView } from "./components/DeleteAccountView/deleteAccountView";
import { AboutYourSelfView } from "./components/AdvertisementView/aboutYourSelfView";
import { HowWeReachYouView } from "./components/AdvertisementView/howWeReachYouView";
import { AffordabilityView } from "./components/AdvertisementView/affordabilityView";
import { PendingSignatureView } from "./components/PendingSignature/pendingSignature";
import { BookingSuccessView } from "./components/BookingSuccessView/bookingSuccessView";
import { MaxAffordabilityView } from "./components/AdvertisementView/maxAffordabilityView";
import { EnableNotifications } from "./components/EnableNotifications/enableNotifications";
import { ProfileOverviewView } from "./components/ProfileOverviewView/profileOverviewView";
import { OwnershipIntroView } from "./components/HeroHomeOwnershipView/ownershipIntroView";
import { MonthlyDebtInputView } from "./components/MonthlyDebtInputView/monthlyDebtInputView";
import { PointsOfInterestView } from "./components/PointsOfInterestView/pointsOfInterestView";
import { AnnualIncomeInputView } from "./components/AnnualIncomeInputView/annualIncomeInputView";
import { PropertySearchingView } from "./components/PropertySearchingView/propertySearchingView";
import { MaximumAffordabilityView } from "./components/MaximumAffordability/maximumAffordability";
import { AffordabilityCheckView } from "./components/AffordabilityCheckView/affordabilityCheckView";
import { PropertyPreferencesView } from "./components/PropertyPreferencesView/propertyPreferencesView";
import { DetailsConfirmationView } from "./components/DetailsConfirmationView/detailsConfirmationView";
import { ShowingDateSelectionView } from "./components/ShowingDateSelectionView/showingDateSelectionView";
import { ShowingTimeSelectionView } from "./components/ShowingTimeSelectionView/showingTimeSelectionView";
import { BuyerExploreListingsView } from "./components/BuyerExploreListingsView/buyerExploreListingsView";
import { PropertyMatchOverviewView } from "./components/PropertyMatchOverviewView/propertyMatchOverviewView";
import { PreApprovalDocsUploadView } from "./components/PreApprovalDocsUploadView/preApprovalDocsUploadView";
import { PropertyCitySelectionView } from "./components/PropertyCitySelectionView/propertyCitySelectionView";
import { GetPreApprovedOverviewView } from "./components/GetPreApprovedOverviewView/getPreApprovedOverviewView";
import { CheckoutCartPropertiesView } from "./components/CheckoutCartPropertiesView/checkoutCartPropertiesView";
import { GetPreApprovedEmailInputView } from "./components/GetPreApprovedEmailInputView/getPreApprovedEmailInputView";
import { GetPreApprovedCheckInboxView } from "./components/GetPreApprovedCheckInboxView/getPreApprovedCheckInboxView";
import { ComparativeMarketAnalysisView } from "./components/ComparativeMarketAnalysisView/comparativeMarketAnalysisView";
import { SearchView } from "./components/SearchView/searchView";

export const routes = (): RouteObject[] => {
	return [
		{
			path: "/login",
			element: <AuthLayout type="public" />,
			children: [
				{
					path: "",
					element: <WelcomeView />,
					errorElement: <ErrorView />,
				},
				{
					path: "intro",
					element: <IntroView />,
					errorElement: <ErrorView />,
				},
				{
					path: "",
					element: <AdvertisementFlow />,
					children: [
						{
							path: "about-yourself-view",
							element: <AboutYourSelfView />,
							errorElement: <ErrorView />,
						},
						{
							path: "how-reach-you-view",
							element: <HowWeReachYouView />,
							errorElement: <ErrorView />,
						},
					],
				},

				{
					path: "",
					element: <LoginLayout />,
					children: [
						{
							path: "email-view",
							element: <LoginEmailView />,
							errorElement: <ErrorView />,
						},
						{
							path: "verify-view",
							element: <LoginVerifyView />,
							errorElement: <ErrorView />,
						},
					],
				},
			],
		},
		{
			path: "/",
			element: <AuthLayout type="private" />,
			children: [
				{
					path: "/",
					element: <MainLayout />,
					errorElement: <ErrorView />,
					children: [
						{
							path: "/",
							element: <BuyerMainView />,
							errorElement: <ErrorView />,
						},

						{
							path: "/explore",
							element: <BuyerExploreListingsView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile",
							element: <ProfileView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart",
							element: <CartView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/comparison",
							element: <CartComparison />,
							errorElement: <ErrorView />,
						},

						{
							path: "/finances",
							element: <FinanceView />,
							errorElement: <ErrorView />,
						},
					],
				},
				{
					path: "/",
					element: <AdvertisementFlow />,
					children: [
						{
							path: "/income-view",
							element: <IncomeView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/saving-view",
							element: <SavingView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/debt-view",
							element: <DebtView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/max-affordability-view",
							element: <MaxAffordabilityView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/affordability-view",
							element: <AffordabilityView />,
							errorElement: <ErrorView />,
						},
					],
				},
				{
					path: "/",
					element: <SubRouteLayout />,
					children: [
						{
							path: "/profile/prioritize",
							element: <Prioritize />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/enable-notifications",
							element: <EnableNotifications />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/life-style",
							element: <LifeStyle />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/needs-wants",
							element: <NeedsWants />,
							errorElement: <ErrorView />,
						},

						{
							path: "/profile/points-interest",
							element: <PointsInterest />,
							errorElement: <ErrorView />,
						},
						{
							path: "/select-city",
							element: <BuyerSearchView />,
							errorElement: <ErrorView />,
						},
						{

							path: "/search",
							element: <SearchView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/showing",
							element: <ShowingView filterStatus={"upcoming"} />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/showing/completed",
							element: <ShowingView filterStatus={"completed"} />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/notification",
							element: <NotificationView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/delete-account",
							element: <DeleteAccountView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/delete-account/miss-you",
							element: <DeleteAccountMissYouView />,
							errorElement: <ErrorView />,
						},

						{
							path: "/profile/overview",
							element: <ProfileOverviewView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/liked-homes",
							element: <LikedHomesView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/upload-preapproved",
							element: <PreApprovalDocsUploadView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/get-preapproved",
							element: <GetPreApprovedOverviewView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/get-preapproved/email-view",
							element: <GetPreApprovedEmailInputView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/profile/get-preapproved/check-inbox",
							element: <GetPreApprovedCheckInboxView />,
							errorElement: <ErrorView />,
						},

						{
							path: "/cart/checkout-cart-properties",
							element: <CheckoutCartPropertiesView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/date-selection",
							element: <ShowingDateSelectionView />,
							errorElement: <ErrorView />,
						},

						{
							path: "/cart/time-selection",
							element: <ShowingTimeSelectionView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/details-confirmation",
							element: <DetailsConfirmationView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/contract",
							element: <ContractView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/booking-success",
							element: <BookingSuccessView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/cart/pending-signature",
							element: <PendingSignatureView />,
							errorElement: <ErrorView />,
						},
						{
							path: "/listing/:zlid/cma",
							element: <ComparativeMarketAnalysisView />,
							errorElement: <ErrorView />,
						},
					],
				},
				{
					path: "/listing/:zlid",
					element: <ListingView />,
					errorElement: <ErrorView />,
				},
				{
					path: "/listing/:zlid/images",
					element: <ImagesView />,
					errorElement: <ErrorView />,
				},

				{
					path: "/ownership",
					element: <OwnershipIntroView />,
				},

				{
					path: "/buyer-input-flow",
					element: <BuyerInputFlow />,
					children: [
						{
							path: "/buyer-input-flow/details-input",
							element: <DetailsInputView />,
						},
						{
							path: "/buyer-input-flow/saving",
							element: <SavingInputView />,
						},
						{
							path: "/buyer-input-flow/annual-income",
							element: <AnnualIncomeInputView />,
						},
						{
							path: "/buyer-input-flow/monthly-debt",
							element: <MonthlyDebtInputView />,
						},
						{
							path: "/buyer-input-flow/affordability",
							element: <AffordabilityCheckView />,
						},
						{
							path: "/buyer-input-flow/max-affordability",
							element: <MaximumAffordabilityView />,
						},
						{
							path: "/buyer-input-flow/property-preferences",
							element: <PropertyPreferencesView />,
						},
						{
							path: "/buyer-input-flow/property-searching",
							element: <PropertySearchingView />,
						},
						{
							path: "/buyer-input-flow/city-selection",
							element: <PropertyCitySelectionView />,
						},
						{
							path: "/buyer-input-flow/property-match",
							element: <PropertyMatchOverviewView />,
						},
						{
							path: "/buyer-input-flow/points-interest",
							element: <PointsOfInterestView />,
						},
						{
							path: "/buyer-input-flow/lifestyle",
							element: <LifestyleView />,
						},
						{
							path: "/buyer-input-flow/needs-wants",
							element: <NeedsVsWantsView />,
						},
						{
							path: "/buyer-input-flow/priorities",
							element: <PrioritiesView />,
						},
					],
				},
			],
		},
	];
};
