import type { TimePreference } from "../interfaces/timePreference";

/**
 * Generates an array of initial time preferences for the given dates.
 *
 * This function takes an array of date strings and returns an array of `TimePreference` objects.
 * Each `TimePreference` includes a unique `id` based on the date and a random string, as well as
 * empty values for `startAt`, `endAt`, and `error`.
 *
 * @param {string[]} dates - An array of date strings for which time preferences need to be created.
 * @returns {TimePreference[]} - An array of `TimePreference` objects with initial values.
 *
 * @example
 * // Returns an array of TimePreference objects with empty startAt and endAt
 * generateInitialTimePreferences(['2024-10-01', '2024-10-02']);
 */

export const generateInitialTimePreferences = (
	dates: string[],
): TimePreference[] => {
	return dates.map((date) => ({
		id: `${date}-${Math.random().toString(36).substring(2, 11)}`,
		date,
		startAt: "",
		endAt: "",
		error: "",
	}));
};
