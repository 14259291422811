import { universalFetch } from "./common";

export interface DemographicResponse {
	counts?: { countName?: string; value?: number }[];
	percentages?: { percentageName?: string; value?: number }[];
	pies?: { pieName?: string; sliceName?: string; value?: number }[][];
}

export const demographics = async (listingId: string): Promise<DemographicResponse> => {
  try {
    const response = await universalFetch(`/listings/${listingId}/statcan`, "GET");

    return response as DemographicResponse;
  } catch (error) {
    console.error("Failed fetchCurrentQualification", error);
    throw error;
  }
};
