interface ProgressBarProps {
	totalSteps: number;
}

const ProgressBar = ({ totalSteps }: ProgressBarProps) => {
	return (
		<div className="bg-[#F2F2F2] h-3 rounded-md absolute top-1/2 -translate-y-1/2 left-6 right-6">
			<div
				className="bg-z-dark-moss-green rounded-full h-full transition-all"
				style={{
					width: `${totalSteps}%`,
				}}
			/>
		</div>
	);
};

export { ProgressBar };
